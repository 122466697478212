import {WorksheetPage} from "../../_model/WorksheetPage";
import {WDPageHistoryItemElement} from "./WDPageHistoryItemElement";
import {WDHistoryItem} from "./WDHistoryItem";

export class WDPageHistoryItem implements WDHistoryItem {
    elements: WDPageHistoryItemElement[]

    constructor(elements: WorksheetPage[]) {
        this.elements = elements.map(item => new WDPageHistoryItemElement(item))
    }
}
