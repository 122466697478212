import {ElementLayout} from "../Designer/Elements/WDElementContainer";
import {Coords} from "./Coords";

export enum DrawBorderStyle {
    round = "round",
    miter = "miter",
    bevel = "bevel"
}
export enum DrawLineStyle {
    dashed = "dashed",
    dotted = "dotted",
    solid = "solid",
    dashed_medium = "dashed_medium",
    dashed_wide = "dashed_wide"
}

export class DrawBorder {
    color: string
    style: DrawBorderStyle
    width: number

    constructor(color: string,
                style: DrawBorderStyle,
                weight: number) {

        this.color = color
        this.style = style
        this.width = weight
    }
}

export class Draw {
    canvas: HTMLCanvasElement
    ctx: CanvasRenderingContext2D

    fillColor: string = "transparent"

    constructor(canvas: HTMLCanvasElement) {
        this.canvas = canvas;

        let ctx = canvas.getContext("2d");
        if (ctx) {
            this.ctx = ctx
        }
        else {
            this.ctx = new CanvasRenderingContext2D()
        }
    }
    static cloneCanvas(oldCanvas: HTMLCanvasElement) {


        // Create an image element
        const image = new Image();

        // Assign the canvas content as the source of the image
        image.src = oldCanvas.toDataURL();
        // image.style.left = 0 + "px"
        // image.style.top = 0 + "px"
        // image.style.width = oldCanvas.width + "px"
        // image.style.height = oldCanvas.height + "px"

        //return the new canvas
        return image
    }

    private getLineDash(style: DrawLineStyle) {
        switch (style) {
            case DrawLineStyle.solid:
                return []
            case DrawLineStyle.dotted:
                return [2, 2]
            case DrawLineStyle.dashed:
                return [10, 10]
            case DrawLineStyle.dashed_medium:
                return [15, 7]
            case DrawLineStyle.dashed_wide:
                return [20, 5]
        }
    }

    setBorder(border: DrawBorder) {
        this.ctx.lineJoin = border.style
        this.ctx.lineWidth = border.width
        this.ctx.strokeStyle = border.color
    }
    setLineStyle(style: DrawLineStyle) {
        this.ctx.setLineDash(this.getLineDash(style ? style : DrawLineStyle.solid))
    }
    setFillColor(color: string) {
        this.ctx.fillStyle = color
    }
    setLineWidth(width: number) {
        this.ctx.lineWidth = width
    }

    Clear() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    }

    Line(start: Coords, end: Coords): Path2D {
        let path = new Path2D()
        path.moveTo(start.x, start.y)
        path.lineTo(end.x, end.y)
        this.ctx.stroke(path)

        return path
    }
    LineDotted(start: Coords, end: Coords, dotRadius, spaceSize) {
        let diameter = dotRadius * 2

        // needed because line can be upside down
        let factorX = start.x > end.x ? -1 : 1
        let factorY = start.y > end.y ? -1 : 1

        // Gradient
        let dx= end.x - start.x
        let dy= end.y - start.y

        // Hypotenuse
        let lineLength = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))

        // Size of dots and spaces between them
        let spaceSizeX= dx / lineLength * spaceSize * 2
        let spaceSizeY= dy / lineLength * spaceSize * 2
        let dotSizeX = dx / lineLength * diameter
        let dotSizeY = dy / lineLength * diameter

        // Starting position needs to be set further away because circle position given is always center
        let position = new Coords(start.x + dotSizeX / 2, start.y + dotSizeY / 2)

        // Draw circles as long as there is space for them
        while ((position.x + dotSizeX) * factorX <= end.x * factorX && (position.y + dotSizeY) * factorY <= end.y * factorY) {
            this.Circle(position, dotRadius)
            position = new Coords(position.x + dotSizeX + spaceSizeX, position.y + dotSizeY + spaceSizeY)
        }
    }
    Text(text: string, pos: Coords) {
        this.ctx.fillStyle = "black"
        this.ctx.fillText(text, pos.x, pos.y)
    }
    Ellipse(pos: Coords, radius1: number, radius2: number) {
        this.ctx.beginPath()
        this.ctx.ellipse(pos.x, pos.y, radius1, radius2, 0, 0, 2 * Math.PI)
        this.ctx.stroke()
        this.ctx.fill()
    }
    SemiCircle(startPos:Coords, middlePos:Coords, endPos:Coords, radius1: number, radius2: number) {
        this.ctx.beginPath()
        this.ctx.ellipse(middlePos.x, middlePos.y, radius1, radius2, 0, -Math.PI, Math.PI)
        this.ctx.stroke()
        this.ctx.fill()

        this.Line(startPos, endPos)
    }
    Polygon(pos: Coords[]) {
        if (pos === null || pos.length === 0) {
            return
        }

        this.ctx.beginPath()
        this.ctx.moveTo(pos[0].x, pos[0].y)
        for (let i = 1; i < pos.length; i++) {
            this.ctx.lineTo(pos[i].x, pos[i].y)
        }
        this.ctx.closePath()
        this.ctx.stroke()
        this.ctx.fill()
    }
    Cross(pos: Coords, side: number, edge: number) {
        this.Polygon([
            new Coords(pos.x - edge - side, pos.y - side),
            new Coords(pos.x - side, pos.y - side),
            new Coords(pos.x - side, pos.y - side - edge),
            new Coords(pos.x + side, pos.y - side - edge),
            new Coords(pos.x + side, pos.y - side),
            new Coords(pos.x + edge + side, pos.y - side),
            new Coords(pos.x + edge + side, pos.y + side),
            new Coords(pos.x + side, pos.y + side),
            new Coords(pos.x + side, pos.y + side + edge),
            new Coords(pos.x - side, pos.y + side + edge),
            new Coords(pos.x - side, pos.y + side),
            new Coords(pos.x - edge - side, pos.y + side),
        ])
    }
    Arrow(borderWidth: number) {
        this.Polygon([
            new Coords(this.canvas.width - borderWidth / 2, this.canvas.height / 2),
            new Coords(this.canvas.width * 2/3, this.canvas.height - borderWidth / 2),
            new Coords(this.canvas.width * 2/3, this.canvas.height * 2/3),
            new Coords(borderWidth / 2, this.canvas.height * 2/3),
            new Coords(borderWidth / 2, this.canvas.height / 3),
            new Coords(this.canvas.width * 2/3, this.canvas.height / 3),
            new Coords(this.canvas.width * 2/3, borderWidth / 2),
        ])
    }
    Star(pos: Coords, spikes: number, r1: number, r2: number, rotation?: number) {
        let rot = Math.PI / 2 * 3

        if(rotation) {
            rot *= rotation
        }

        let step = Math.PI / spikes

        let x = pos.x
        let y = pos.y

        this.ctx.beginPath();
        this.ctx.moveTo(x + Math.cos(rot) * r2, y + Math.sin(rot) * r2)
        for (let i = 0; i < spikes; i++) {
            x = pos.x + Math.cos(rot) * r2
            y = pos.y + Math.sin(rot) * r2
            this.ctx.lineTo(x, y)
            rot += step

            x = pos.x + Math.cos(rot) * r1
            y = pos.y + Math.sin(rot) * r1
            this.ctx.lineTo(x, y)
            rot += step
        }
        this.ctx.lineTo(pos.x + Math.cos(rot) * r2, pos.y + Math.sin(rot) * r2)
        this.ctx.closePath()
        this.ctx.stroke()
        this.ctx.fill()
    }
    Rect(dim: ElementLayout) {
        this.Polygon([
            new Coords(dim.left, dim.top),
            new Coords(dim.width, dim.top),
            new Coords(dim.width, dim.height),
            new Coords(dim.left, dim.height)
        ])
    }
    Circle(pos: Coords, radius: number) {
        this.Ellipse(pos, radius, radius)
    }
    Arc(pos: Coords, radius: number, startAngle: number, endAngle: number, counterClockwise: boolean) {
        this.ctx.beginPath()
        this.ctx.fillStyle = "#FF0000"
        this.ctx.arc(pos.x, pos.y, radius, startAngle, endAngle, counterClockwise)

        this.ctx.stroke()
    }

    IsPointInStroke(path: Path2D, pos: Coords) {
        return this.ctx.isPointInStroke(path, pos.x, pos.y)
    }
}
