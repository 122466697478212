import React from "react";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../WDElementBase";
import {ResizeInfo, WDElementContainer} from "../WDElementContainer";
import Const from "../../../Framework/Const";
import {MainContext} from "../../../_base/MainContext";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {NameValueToEnumKey} from "../../../Framework/Enums";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../Framework/CategoryImage";

export enum WDShapeTypeBuildingBrick {
    Cube = "2",
    Cube_Rotated = "3",
    Cuboid = "0",
    Cuboid_High_Flat= "1",
    Cuboid_Flat= "4" ,
    Cuboid_Flat_Rotated= "5",
    Cuboid_Hole= "6",
    Triangle_Right = "7",
    Triangle_Left = "8",
    Triangle_Middle = "9",
    Pyramid_High = "10",
    Pyramid_Rotated = "11",
    Pyramid = "12",
    Pyramid_High_Cut = "13",
    Pyramid_Cut = "14",
    Cone_Cut = "15",
    Cone = "16",
    Cylinder = "17",
    Cylinder_Flat = "18",
    Bridge = "19",
    Bridge_Left = "20",
    Bridge_Right = "21",
    Semicircle = "22",
    Cuboid_Flat_Laying = "23"
}
export enum WDShapeColorBuildingBrick {
    "#d41e88" = "pink",
    "#ff0000" = "rot",
    "#f49819" = "orange",
    "#f2bd20" = "gelb",
    "#73a62d" = "grün",
    "#82afd9" = "blau",
    "#c485b8" = "lila",
}

export class WDShapeDataBuildingBrick extends WDElementBaseData {
    type: WDShapeTypeBuildingBrick

    constructor(type: WDShapeTypeBuildingBrick) {
        super()
        this.type = type
    }

    static serialize = (data: WDShapeDataBuildingBrick): string => {
        return JSON.stringify(data, (key, value) => {
            if (key === "url" || key === "width" || key === "height") return undefined;
            else return value;
        })
    }
}

interface IProps extends WDElementBaseProps {
    data: WDShapeDataBuildingBrick
    isReadOnly: boolean
}

interface IState extends WDElementBaseState {
}

export class WDShapeBuildingBrick extends WDElementBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        this.state = {
            isEdited: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            elementRef: React.createRef()
        }
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    getMinWidth = () => {
        return 80
    }
    getMinHeight = () => {
        return 80
    }

    static getDefaultWidth = () => {
        return 150
    }
    static getDefaultHeight = () => {
        return 150
    }
    static mapImageKeyToShapeType = (imageKey: string) : WDShapeTypeBuildingBrick => {
        let type = imageKey.replace("FORM_BRICK_", "")
        return WDShapeTypeBuildingBrick[NameValueToEnumKey(type, WDShapeTypeBuildingBrick)]
    }
    static mapShapeTypeToImage = (type: WDShapeTypeBuildingBrick, color: string) : string => {
        let imageKey = "FORM_BRICK_" + type
        let colorName = WDShapeColorBuildingBrick[color]
        return CategoryImageValue.getImageByKey([ImageCategory.FORMS_BUILDING_BRICK], imageKey, [colorName])
    }
    mapImageValueToShapeType = (imageValue: string) : WDShapeTypeBuildingBrick => {
        let imageValueArray = imageValue.split("-")
        return WDShapeTypeBuildingBrick[NameValueToEnumKey(imageValueArray[4], WDShapeTypeBuildingBrick)]
    }


    doAction = (action: WDToolbarAction, data?: string) => {
        let newData = {...this.props.data}

        let update = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDToolbarAction.CHANGE_GRAPHIC:
                if(data && data["image"]) {
                    newData.type = this.mapImageValueToShapeType(data["image"])
                }
                break


            default:
                break
        }

        update.value.content = this.serializeElementData(newData)
        return update
    }

    onResizeElement = (proportional: boolean, x: number, y: number) => {
        this.props.onElementResize?.(true, x, y)
    }

    /**
     * Overridden methods
     */
    hasNameConfigInstancesEnabled = (): boolean => {
        return false
    }
    serializeElementData = (data: WDElementBaseData): string => {
        return WDShapeDataBuildingBrick.serialize(data as WDShapeDataBuildingBrick)
    }

    render() {
        let resizeInfo: ResizeInfo =
            new ResizeInfo(!this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly,
                false, false, false, false,
                this.getMinWidth(), Const.MaxElementSize, this.getMinHeight(), Const.MaxElementSize)

        // if element is marked as deleted, do not render
        if(this.props.element.deleted) {
            return <></>
        }

        let image = WDShapeBuildingBrick.mapShapeTypeToImage(this.props.data.type, this.props.element.fillColor || "#f2bd20")

        return <WDElementContainer
            id={this.props.id}
            element={this.props.element}
            hasResizeOnCreate={false}
            resizeInfo={resizeInfo}
            renderWrapper={true}
            renderBackgroundColor={false}
            renderBorder={false}
            onUnlockElement={this.unlockElement}
            onResizeStateChanged={this.props.onResizeStateChanged}
            onResizeElement={this.onResizeElement}
            isEditModeAllowed={() => false}
            isReadOnly={this.props.isReadOnly}
            onContextMenu={this.props.onContextMenu}
            ref={this.state.elementRef}
        >

            <div className={"ws-designer-shape print"}>
                <img className={"ws-designer-shape-image"} alt={""}
                     src={process.env.PUBLIC_URL + ImagePath.getFormsUrl() + image}/>
            </div>

        </WDElementContainer>
    }
}
