import {PageBorderPosition} from "../Components/PageBorder/WDPageBorder";

export class WorksheetPage {
    id?: number
    key: string
    solution: boolean
    borderImage?: number
    borderLeft: number
    borderTop: number
    borderRight: number
    borderBottom: number
    borderColor?: string
    borderStyle?: string
    borderWeight?: number
    borderRadius?: number
    borderTransparency?: number
    trimBorderLeft: number
    trimBorderTop: number
    trimBorderRight: number
    trimBorderBottom: number
    linkBorders: boolean
    sort: number
    deleted: boolean = false
    changed: boolean = false

    constructor(key: string, solution: boolean, pageBorder: PageBorderPosition, trimBorder: PageBorderPosition, sort: number) {
        this.key = key
        this.solution = solution
        this.borderLeft = pageBorder.left
        this.borderTop = pageBorder.top
        this.borderRight = pageBorder.right
        this.borderBottom = pageBorder.bottom
        this.trimBorderLeft = trimBorder.left
        this.trimBorderTop = trimBorder.top
        this.trimBorderRight = trimBorder.right
        this.trimBorderBottom = trimBorder.bottom
        this.linkBorders = pageBorder.link
        this.sort = sort
    }

    static getNewPageKey () {
        return "page-" + Date.now() + Math.floor(100000 + Math.random() * 900000)
    }

    // Has to be static so the mapping from backend works
    static getUniqueElementIdentifier(page: WorksheetPage) {
        let id = "ws-designer-sheet-"

        if (page.key !== undefined) {
            id += page.key
        }
        else {
            id += page.id
        }

        return id
    }

    static duplicate(item: WorksheetPage): WorksheetPage {
        let newItem = {...item}
        newItem.key = WorksheetPage.getNewPageKey()
        newItem.id = undefined

        return newItem
    }
    static mergeWorksheetPage(newWP, oldWP): WorksheetPage {
        newWP.key = newWP.key || oldWP.key
        newWP.solution = newWP.solution || oldWP.solution
        newWP.borderImage = newWP.borderImage || oldWP.borderImage
        newWP.borderLeft = newWP.borderLeft || oldWP.borderLeft
        newWP.borderTop = newWP.borderTop || oldWP.borderTop
        newWP.borderRight = newWP.borderRight || oldWP.borderRight
        newWP.borderColor = newWP.borderColor || oldWP.borderColor
        newWP.borderStyle = newWP.borderStyle || oldWP.borderStyle
        newWP.borderWeight = newWP.borderWeight || oldWP.borderWeight
        newWP.borderRadius = newWP.borderRadius || oldWP.borderRadius
        newWP.borderTransparency = newWP.borderTransparency || oldWP.borderTransparency
        newWP.trimBorderLeft = newWP.trimBorderLeft || oldWP.trimBorderLeft
        newWP.trimBorderTop = newWP.trimBorderTop || oldWP.trimBorderTop
        newWP.trimBorderRight = newWP.trimBorderRight || oldWP.trimBorderRight
        newWP.trimBorderBottom = newWP.trimBorderBottom || oldWP.trimBorderBottom
        newWP.linkBorders = newWP.linkBorders || oldWP.linkBorders
        newWP.sort = newWP.sort || oldWP.sort

        return newWP
    }
}
