import React from "react";
import {MainContext} from "../../../_base/MainContext";
import {SidebarFilterBase} from "../SidebarFilterBase";
import {SidebarFilterButtonData} from "../SidebarFilterButtonData";
import translations from "../../../Framework/translations.json";
import {WorksheetPage} from "../../../_model/WorksheetPage";
import {TooltipPosition, TooltipText} from "../../../Components/Tooltips";
import Image, {ImageAlignment, ImageFilter, ImageFilterAdmin} from "../../../_model/Image";
import {GetImageGalleryFiltered} from "../../../_endpoint/ImageEndpoint";
import {WDUtils} from "../../Utils/WDUtils";
import {
    SidebarContentBase,
    SidebarContentBaseProps,
    SidebarContentBaseState,
    SidebarContentDragElementType
} from "../SidebarContentBase";
import {WSContextType} from "../../Elements/WSContext";
import {ImageGallery} from "../../../Components/Images/ImageGallery";
import {ButtonInfo, ButtonList} from "../../../Components/Controls/ButtonList";
import {DropDown, DropDownItem, DropDownType, OpeningDirection} from "../../../Components/Controls/DropDown";
import {
    EntityToEnumValue,
    EnumToEntityArray,
    EnumValuesToEntityArray,
    EnumValueToEntity,
} from "../../../Framework/Enums";
import {PageBorderPosition, WDPageBorder} from "../../../Components/PageBorder/WDPageBorder";
import RadioButtonList from "../../../Components/Controls/RadioButtonList";
import Entity from "../../../_model/Entity";
import {BorderStyle, BorderStylingOptions} from "../../../Components/Controls/BorderStylingOptions";
import {NumberInput} from "../../../Components/Controls/NumberInput";
import {WDToolbarButton} from "../../Toolbar/Button/WDToolbarButton";
import {ImagePath} from "../../../Framework/CategoryImage";
import {WDToolbarButtonColorPicker} from "../../Toolbar/Button/General/WDToolbarButtonColorPicker";
import Const from "../../../Framework/Const";
import {WDSheetPageBorderPreview} from "./WDSheetPageBorderPreview";
import {FrameImageListFilter} from "./FrameImageListFilter";
import ListSortSetting from "../../../Components/List/ListSortSetting";

interface IProps extends SidebarContentBaseProps {
    worksheetType: WSContextType
    pages: WorksheetPage[]
    orientation: boolean
    currentPageIndex: number

    onChangePageBorder: (pageActionSubject: FrameSubject, borderPosition: PageBorderPosition, borderStyle: BorderStyle,
                         borderRadius?: number, borderTransparency?: number, borderImage?: number) => void
    onToggleNonPrintObjectsVisibility: () => void

    showNonPrintableObjects: boolean
    isEditingAllowed: boolean
}
interface IState extends SidebarContentBaseState {
    data?: Image[]
    actionSubject: FrameSubject

    manualValuesEntered: boolean
    colorPickerOpen: boolean

    filter: FrameImageListFilter
    filterHistory: FrameImageListFilter[]
}

export enum FrameSubject {
    "CURRENT_PAGE" = "current_page",
    "ALL_PAGES" = "all_pages",
    "FIRST_PAGE" = "first_page"
}
export enum FrameType {
    "LINE" = "line",
    "GRAPHIC" = "graphic"
}

export class SidebarFrames extends SidebarContentBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    PAGE_SIZE: number = 100
    frameSubjects: DropDownItem[] = []

    // Do not remove state as parameter -> otherwise translation of frameSubjects do not work anymore!!
    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            filter: FrameImageListFilter.initFilter(),
            filterHistory: [],
            filterHistoryIndex: 0,
            lastItem: false,
            page: 0,

            manualValuesEntered: false,
            colorPickerOpen: false,

            actionSubject: FrameSubject.CURRENT_PAGE,
        }

        this.frameSubjects.push(...EnumToEntityArray(FrameSubject, translations.enum.frame_subject, this.context.translate).map(
            i => new DropDownItem(i.id!.toString(), i.name)
        ))
    }

    componentDidMount() {
        this.fetchData(true)
        this.setPageBorderOptions()
    }
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(prevProps.currentPageIndex !== this.props.currentPageIndex) {
            this.setPageBorderOptions()
        }
        if(prevProps.orientation !== this.props.orientation) {
            this.fetchData(false)
        }
    }

    fetchData = (addHistory: boolean, append: boolean = false, callback?: (page: number, lastPage: boolean) => void) => {
        const filter = new ImageFilter()
        filter.color = this.state.filter.colors
        filter.property = this.state.filter.properties

        if (this.state.filter.search.length > 0) {
            filter.search = this.state.filter.search
        }

        filter.sort = new ListSortSetting("rating", true)
        filter.alignment = this.props.orientation ? [ImageAlignment.portrait] : [ImageAlignment.landscape]
        filter.pageFrame = true
        filter.hasCounterpartImage = true
        filter.adminFilter = new ImageFilterAdmin()

        let page = append ? this.state.page + 1 : 0
        GetImageGalleryFiltered(filter, this.props.worksheetType, page, this.PAGE_SIZE).then(
            (itemData) => {
                if (addHistory) {
                    this.pushHistory(this.state.filter)
                }

                let items = (itemData.content as Image[]).filter(item => item.url !== null)
                if (append && this.state.data) {
                    // Page number would be increased by 1 but if no items are retrieved keep page number
                    if (items.length === 0) {
                        page --
                    }
                    items = this.state.data?.concat(items)
                }
                let lastItemReached = WDUtils.lastItemResultReached(itemData.content.length, this.PAGE_SIZE)

                this.setState({data: items, page: page, lastItem: lastItemReached},
                    () => callback?.(page, lastItemReached))
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }
    onFetchNextPage = (callback?: (page: number, lastPage: boolean) => void) => {
        this.fetchData(false, true, callback)
    }
    setPageBorderOptions = (frameType?: FrameType) => {
        if(!this.state.manualValuesEntered && this.props.currentPageIndex < this.props.pages.length) {
            let filter = this.state.filter
            let currentPage = this.props.pages[this.props.currentPageIndex]
            let isMatchingFrameType = frameType ? this.isPageMatchingFrameType(currentPage) : true

            filter.frameType = frameType || this.getFrameType(currentPage)

            if(isMatchingFrameType) {
                filter.borderOptions = new PageBorderPosition(
                    currentPage.borderLeft,
                    currentPage.borderTop,
                    currentPage.borderRight,
                    currentPage.borderBottom,
                    currentPage.linkBorders)

                filter.borderStyle = new BorderStyle(
                    false,
                    currentPage.borderColor || "transparent",
                    currentPage.borderStyle || "none",
                    currentPage.borderWeight || 0
                )

                filter.borderRadius = currentPage.borderRadius || 0
                filter.borderTransparency = currentPage.borderTransparency || 0
            }

            if (this.state.filter.frameType === FrameType.GRAPHIC) {
                filter.borderImage = currentPage.borderImage
            }

            filter.borderPreview.borderLeft = filter.borderOptions.left
            filter.borderPreview.borderTop = filter.borderOptions.top
            filter.borderPreview.borderRight = filter.borderOptions.right
            filter.borderPreview.borderBottom = filter.borderOptions.bottom
            filter.borderPreview.borderColor = filter.borderStyle.color
            filter.borderPreview.borderStyle = filter.borderStyle.style
            filter.borderPreview.borderWeight = filter.borderStyle.weight
            filter.borderPreview.borderRadius = filter.borderRadius
            filter.borderPreview.borderTransparency = filter.borderTransparency

            this.setState({
                filter: filter
            })
        }
    }
    getFrameType = (currentPage: WorksheetPage) : FrameType => {
        if (currentPage.borderImage) {
            return FrameType.GRAPHIC
        }
        else if ((currentPage.borderImage === undefined || currentPage.borderImage === null)
            && (currentPage.borderColor !== undefined && currentPage.borderColor !== null && currentPage.borderColor !== "transparent")
            && (currentPage.borderWeight !== undefined && currentPage.borderWeight !== null && currentPage.borderWeight !== 0)
            && (currentPage.borderStyle !== undefined && currentPage.borderStyle !== null && currentPage.borderStyle !== "none")
        ) {
            return FrameType.LINE
        }

        return FrameType.GRAPHIC
    }
    isPageMatchingFrameType = (currentPage: WorksheetPage) : boolean => {
        return (currentPage.borderImage !== null && this.state.filter.frameType === FrameType.GRAPHIC)
            || (currentPage.borderImage === null && this.state.filter.frameType === FrameType.LINE)
    }

    onClickImage = (image: Image) => {
        let filter = this.state.filter
        filter.borderImage = image.id

        this.setState({filter: filter, manualValuesEntered: true}, () => this.pushHistory(this.state.filter))
    }
    onDragImage = (event: React.DragEvent, image: Image) => {
        event.dataTransfer.setData(Const.DATA_TRANSFER_IMAGE_COLOR, this.state.filter.borderStyle.color)
        this.onDragStart(event, SidebarContentDragElementType.FRAME, "sidebar-image-" + image.id!.toString(), image)
    }

    onChangeFrameType = (value: Entity) => {
        // reset values
        let filter = FrameImageListFilter.initFilter()
        filter.frameType = EntityToEnumValue(value, FrameType)

        this.setState({
            filter: filter,
            manualValuesEntered: false
        }, () => {
            this.pushHistory(this.state.filter)
            this.setPageBorderOptions(this.state.filter.frameType)
        })
    }
    onChangeFrameSubject = (subject: string) => {
        let subjectValue = Object.values(FrameSubject).find((e, i) => i === +subject)
        this.setState({actionSubject: subjectValue || FrameSubject.ALL_PAGES})
    }
    onChangeBorderPadding = (borderOptions: PageBorderPosition) => {
        let filter = this.state.filter
        filter.borderOptions = borderOptions

        filter.borderPreview.borderLeft = borderOptions.left
        filter.borderPreview.borderTop = borderOptions.top
        filter.borderPreview.borderRight = borderOptions.right
        filter.borderPreview.borderBottom = borderOptions.bottom

        this.setState({
            filter: filter,
            manualValuesEntered: true
        }, () => this.pushHistory(this.state.filter))
    }
    onChangeBorderStyle = (borderStyle: BorderStyle) => {
        let filter = this.state.filter
        filter.borderStyle = new BorderStyle(
            true,
            borderStyle.color,
            borderStyle.style,
            borderStyle.weight
        )

        filter.borderPreview = FrameImageListFilter.initPreviewWithoutPageBorder()
        filter.borderPreview.borderLeft = this.state.filter.borderOptions.left
        filter.borderPreview.borderTop = this.state.filter.borderOptions.top
        filter.borderPreview.borderRight = this.state.filter.borderOptions.right
        filter.borderPreview.borderBottom = this.state.filter.borderOptions.bottom
        filter.borderPreview.borderColor = borderStyle.color
        filter.borderPreview.borderStyle = borderStyle.style
        filter.borderPreview.borderWeight = borderStyle.weight

        filter.borderPreview = WorksheetPage.mergeWorksheetPage(filter.borderPreview, this.state.filter.borderPreview)

        this.setState({
            filter: filter,
            colorPickerOpen: false,
            manualValuesEntered: true
        }, () => this.pushHistory(this.state.filter))
    }
    onChangeBorderTransparency = (borderTransparency: number) => {
        let filter = this.state.filter
        filter.borderTransparency = borderTransparency
        filter.borderPreview.borderTransparency = borderTransparency

        this.setState({
            filter: filter,
            manualValuesEntered: true
        }, () => this.pushHistory(this.state.filter))
    }
    onChangeBorderRadius = (borderRadius: number) => {
        let filter = this.state.filter
        filter.borderRadius = borderRadius
        filter.borderPreview.borderRadius = borderRadius

        this.setState({
            filter: filter,
            manualValuesEntered: true
        }, () => this.pushHistory(this.state.filter))
    }

    onToggleColorPicker = () => {
        this.setState({colorPickerOpen: !this.state.colorPickerOpen})
    }
    onChangeColor = (color: string) => {
        this.onChangeBorderStyle(new BorderStyle(true, color, this.state.filter.borderStyle.style, this.state.filter.borderStyle.weight))
    }

    deleteFilter = () => {
        this.setState({
            filter: FrameImageListFilter.initFilter(),
            manualValuesEntered: false
        }, () => {
            this.setPageBorderOptions()
            this.pushHistory(this.state.filter)
        })
    }
    addPageFrame = () => {
        this.props.onChangePageBorder(
            this.state.actionSubject,
            this.state.filter.borderOptions,
            this.state.filter.borderStyle,
            this.state.filter.borderRadius,
            this.state.filter.borderTransparency,
            this.state.filter.borderImage)
    }
    deletePageFrame = () => {
        this.setState({manualValuesEntered: false}, () => {
            this.props.onChangePageBorder(this.state.actionSubject, PageBorderPosition.getDefaultPageBorder(), BorderStyle.getNonBorderStyle(), 0, 0, undefined)
        })
    }

    render() {
        let pageSubject = ""
        if (this.state.actionSubject) {
            pageSubject = EnumValueToEntity(FrameSubject, this.state.actionSubject, translations.enum.frame_subject, this.context.translate).id!.toString()
        }

        let printableObjectsImage = this.props.showNonPrintableObjects ? "not_printable_off.svg" : "not_printable_on.svg"
        let showNonPrintableObjectsText = this.context.translate(translations.text.worksheet_settings.toggle_non_print_objects) as string
        showNonPrintableObjectsText = showNonPrintableObjectsText.replace("%1%", this.context.translate(this.props.showNonPrintableObjects ? translations.text_fragment.hide : translations.text_fragment.show))
        showNonPrintableObjectsText = showNonPrintableObjectsText[0].toUpperCase() + showNonPrintableObjectsText.substring(1)

        let borderColorClass: string | undefined = undefined
        if(this.state.filter.borderStyle.color && this.state.filter.borderStyle.color !== "transparent") {
            borderColorClass = "svg-color-" + this.state.filter.borderStyle.color.replace("#", "")
        }

        return <div className={"ws-designer-sidebar-container"}>

            {/* Action buttons */}
            <SidebarFilterBase
                onSearch={this.onSearch}
                searchValue={this.state.filter.search}
                buttons={[
                    new SidebarFilterButtonData(1,
                        this.context.translate(translations.command.backward),
                        this.context.translate(translations.tooltip.backward),
                        "navigation_backward.svg",
                        this.state.filterHistoryIndex === 0,
                        this.onClickBackwards,
                        TooltipPosition.belowRight, -138, -5),
                    new SidebarFilterButtonData(2,
                        this.context.translate(translations.command.forward),
                        this.context.translate(translations.tooltip.forward),
                        "navigation_forward.svg",
                        this.state.filterHistoryIndex === this.state.filterHistory.length -1,
                        this.onClickForwards,
                        TooltipPosition.belowRight, -138, -5),
                    new SidebarFilterButtonData(3,
                        showNonPrintableObjectsText,
                        showNonPrintableObjectsText,
                        printableObjectsImage,
                        false,
                        this.props.onToggleNonPrintObjectsVisibility,
                        TooltipPosition.belowRight,-138, -5),
                    new SidebarFilterButtonData(4,
                        this.context.translate(translations.command.delete_manual_values),
                        this.context.translate(translations.tooltip.delete_manual_page_values),
                        "delete_filters.svg",
                        !this.state.manualValuesEntered,
                        this.deleteFilter,
                        TooltipPosition.belowRight, -138, -5),
                    new SidebarFilterButtonData(5,
                        this.context.translate(translations.command.report_frame),
                        this.context.translate(translations.tooltip.report_frame),
                        "mail.svg",
                        this.state.filter.borderImage === undefined,
                        () => this.onClickReport(this.state.filter.borderImage!, this.context.translate(translations.text.frame)),
                        TooltipPosition.belowRight, -138, -5)
                ]}
            />

            {/* Filter */}
            <div className={"ws-designer-sidebar-filter-show ws-designer-sidebar-filter-advanced-show"}>
                <div className={"ws-designer-sidebar-row"} style={{marginBottom: "10px"}}>
                    <div className={"ws-designer-sidebar-column"}>
                        <RadioButtonList
                            id={"rb-frame-type"}
                            border={true}
                            data={EnumValuesToEntityArray(FrameType, Object.values(FrameType), translations.enum.frame_type, this.context.translate)}
                            value={EnumValueToEntity(FrameType, this.state.filter.frameType, translations.enum.frame_type, this.context.translate).id}
                            required={false}
                            readonly={false}
                            width={200}
                            onChange={this.onChangeFrameType}/>
                    </div>

                    <div className={"ws-designer-sidebar-column"} style={{width: "230px"}}>
                        <div className={"form-group"} style={{paddingTop: 0}}>
                            <WDPageBorder
                                border={{
                                    left: this.state.filter.borderOptions.left,
                                    top: this.state.filter.borderOptions.top,
                                    right: this.state.filter.borderOptions.right,
                                    bottom: this.state.filter.borderOptions.bottom,
                                    link: this.state.filter.borderOptions.link,
                                }}
                                smallRendering={true}
                                onChangeBorder={this.onChangeBorderPadding}
                                linkReadOnly={this.state.filter.frameType === FrameType.GRAPHIC}/>
                        </div>
                    </div>
                </div>
            </div>

            {/* Pages */}
            <div id={"ws-designer-sidebar-frame-manager-content"}
                 className={"ws-designer-sidebar-content ws-designer-sidebar-frame-content"}
            >
                {this.state.filter.frameType === FrameType.LINE &&
                    <>
                        <div className={"form-group"} style={{marginBottom: 0}}>
                            <div className={"label-bold"}> {this.context.translate(translations.text.worksheet_settings.page_border_line)} </div>
                        </div>

                        <div className={"ws-designer-sidebar-row"}>
                            <div className={"ws-designer-sidebar-column"}>
                                <div className={"form-group"} style={{marginTop: "3px"}}>
                                    <BorderStylingOptions
                                        id={1}
                                        width={205}
                                        padding={0}
                                        clickableBorderStyle={this.state.filter.frameType === FrameType.LINE}
                                        clickableBorderColor={this.state.filter.frameType === FrameType.LINE}
                                        clickableBorderWeight={this.state.filter.frameType === FrameType.LINE}
                                        tooltipDisabled={new TooltipText(
                                            this.context.translate(translations.toolbar.border.border_color_disabled),
                                            this.context.translate(translations.tooltip.action_disabled_border_graphical)
                                        )}
                                        borderOptions={this.state.filter.borderStyle}
                                        onChangeBorder={this.onChangeBorderStyle}/>
                                </div>
                            </div>
                            <div className={"ws-designer-sidebar-column"} style={{width: "230px"}}>
                                <div className={"form-group"} style={{marginTop: "3px"}}>

                                    <div className={"ws-designer-sidebar-row-separated"}>
                                        <label style={{paddingRight: 5}}>{this.context.translate(translations.toolbar.color_picker.transparency)}</label>
                                        <NumberInput clickable={this.state.filter.frameType === FrameType.LINE}
                                                     width={112} maxlength={3} required={false}
                                                     style={{marginLeft: "4px"}}
                                                     minValue={0} maxValue={100}
                                                     unit={"%"} unitWidth={10}
                                                     onChangeValue={this.onChangeBorderTransparency}
                                                     value={this.state.filter.borderTransparency} />
                                    </div>

                                    <div className={"ws-designer-sidebar-row-separated"} style={{paddingTop: "5px"}}>
                                        <label style={{paddingRight: "3px"}}>{this.context.translate(translations.toolbar.border.border_radius)}</label>
                                        <NumberInput clickable={this.state.filter.frameType === FrameType.LINE}
                                                     width={112} maxlength={2} required={false}
                                                     style={{marginLeft: "4px"}}
                                                     minValue={0} maxValue={50}
                                                     unit={"%"} unitWidth={10}
                                                     onChangeValue={this.onChangeBorderRadius}
                                                     value={this.state.filter.borderRadius} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"form-group"} style={{alignItems: "center"}}>
                        <WDSheetPageBorderPreview
                            worksheetPage={WorksheetPage.duplicate(this.state.filter.borderPreview)}
                            orientation={this.props.orientation}
                            width={350}
                            showNonPrintableObjects={this.props.showNonPrintableObjects}/>
                        </div>
                    </>
                }

                {this.state.filter.frameType === FrameType.GRAPHIC &&
                    <>
                    <div className={"form-group"} style={{marginBottom: 0}}>
                        <label className={"label-bold"}>{this.context.translate(translations.text.worksheet_settings.page_border_graphically)}</label>
                    </div>

                    <div className={"ws-designer-sidebar-row"}>
                        <div className={"ws-designer-sidebar-column"}>
                            <div className={"form-group"} style={{marginTop: 0, marginBottom: 0}}>

                                <div className={"ws-designer-sidebar-row-separated"}>
                                    <label style={{paddingRight: 5}}>{this.context.translate(translations.toolbar.lineColor)}</label>

                                    <div className={"ws-designer-sidebar-row-separated-group-end"}>
                                        <WDToolbarButton id={20}
                                                         icon={ImagePath.getButtonUrl() + "edit.svg"}
                                                         buttonWidth={32}
                                                         buttonHeight={32}
                                                         cssClass={"ws-designer-toolbar-button ws-designer-toolbar-button-m0"}
                                                         color={this.state.filter.borderStyle.color}
                                                         onToggleButton={this.onToggleColorPicker}
                                                         tooltip={new TooltipText(
                                                             this.context.translate(translations.toolbar.border.border_color),
                                                             this.context.translate(translations.tooltip.border_color)
                                                         )}
                                                         tooltipPosition={TooltipPosition.below}
                                                         arrow={false}
                                                         enabled={true}
                                                         pressed={false} />

                                        <WDToolbarButtonColorPicker
                                            id={20}
                                            tooltip={new TooltipText(
                                                this.context.translate(translations.toolbar.border.border_color),
                                                this.context.translate(translations.tooltip.border_color)
                                            )}
                                            open={this.state.colorPickerOpen}
                                            clickable={true}
                                            transparency={true}
                                            onToggleButton={this.onToggleColorPicker}
                                            onChangeColor={this.onChangeColor}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"ws-designer-sidebar-column"} style={{width: "230px"}}>
                            <div className={"form-group"}  style={{marginTop: 0, marginBottom: 0}}>

                                <div className={"ws-designer-sidebar-row-separated"}>
                                    <label style={{paddingRight: 5}}>{this.context.translate(translations.toolbar.color_picker.transparency)}</label>
                                    <NumberInput clickable={true}
                                                 width={112} maxlength={3} required={false}
                                                 style={{marginLeft: "4px"}}
                                                 minValue={0} maxValue={100}
                                                 unit={"%"} unitWidth={10}
                                                 onChangeValue={this.onChangeBorderTransparency}
                                                 value={this.state.filter.borderTransparency} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"ws-designer-sidebar-frame-content-image-container"}>
                        <ImageGallery
                            items={this.state.data}
                            itemHeight={this.props.orientation ? 259 : 139}
                            itemWidth={189}
                            imageClass={borderColorClass}
                            highlightImages={this.state.data?.filter(img => img.id === this.state.filter.borderImage)}
                            imageFieldAsImageTitle={"name"}
                            onFetchNextPage={this.onFetchNextPage}
                            onClickImage={this.onClickImage}
                            onDragImage={this.onDragImage}
                        />
                    </div>
                    </>
                }
            </div>

            {/* Buttons */}
            <div className={"form-row"} style={{marginBottom: "0", paddingTop: "5px", justifyContent: "space-between", alignItems: "center"}}>
                <DropDown
                    id={"selAction"}
                    type={DropDownType.TEXT}
                    value={pageSubject}
                    openingDirection={OpeningDirection.above}
                    readonly={false}
                    autocomplete={false}
                    required={false}
                    width={150}
                    items={this.frameSubjects}
                    style={{marginLeft: "10px"}}
                    onChangeValue={this.onChangeFrameSubject}/>

                <ButtonList
                    containerStyle={{width: "auto"}}
                    buttons={[
                        new ButtonInfo("btnRemove",
                            "button button-save",
                            "button",
                            true,
                            false,
                            this.context.translate(translations.command.remove),
                            this.deletePageFrame,
                            {}),
                        new ButtonInfo("btnAdd",
                            "button button-save",
                            "button",
                            true,
                            false,
                            this.context.translate(translations.command.use),
                            this.addPageFrame,
                            {marginLeft: "10px"})
                    ]}
                    loading={false}
                />
            </div>
        </div>
    }
}
