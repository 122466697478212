import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Const from "../../../Framework/Const";
import {ImagePath} from "../../../Framework/CategoryImage";
import translations from "../../../Framework/translations.json";
import {CreateTag, GetTags} from "../../../_endpoint/MetadataEndpoint";
import TextBox from "../../../Components/Controls/TextBox";
import {
    CreateWorksheet,
    CreateWorksheetPage,
    GetWorksheetById,
    GetWorksheetThumbnail,
    PublishMPWorksheet,
    UpdateWorksheet
} from "../../../_endpoint/WorksheetEndpoint";
import {Worksheet} from "../../../_model/Worksheet";
import {AdminObjectType, Status, WSMarketplaceStatus, WSPageFormat} from "../../../Framework/Enums";
import {Notification} from "../../../Components/Notification/NotificationHandler";
import {MainContext} from "../../../_base/MainContext";
import AdminDataForm from "../../Maintenance/AdminDataForm";
import Tag from "../../../_model/Tag";
import {PageBorderPosition} from "../../../Components/PageBorder/WDPageBorder";
import User from "../../../_model/User";
import FormHeader from "../../../Components/Form/FormHeader";
import Auth from "../../../Framework/Auth";
import {WSContextType} from "../../../Designer/Elements/WSContext";
import {WorksheetPage} from "../../../_model/WorksheetPage";
import SalesMaterial from "../../../_model/SalesMaterial";
import {
    CloneSalesMaterial,
    CreateSalesMaterial,
    GetSalesMaterial,
    UpdateSalesMaterial
} from "../../../_endpoint/SalesMaterialEndpoint";
import CheckBoxSwitch from "../../../Components/Controls/CheckBoxSwitch";
import {Modal} from "../../../Components/Modal";
import {ButtonInfo} from "../../../Components/Controls/ButtonList";

interface MatchParams {
    id: string
}

export interface MatchProps extends RouteComponentProps<MatchParams> {
}

interface IState {
    item: SalesMaterial
    unsavedChanges: boolean
    unsavedWorksheetChanges: boolean
    showPublishDialog: boolean

    worksheetThumbnail?: string
    tags?: Tag[]
}

export default class SalesMaterialForm extends React.Component<MatchProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: MatchProps, state: IState) {
        super(props, state)

        this.state = {
            item: new SalesMaterial("", Status.draft),
            unsavedChanges: false,
            unsavedWorksheetChanges: false,
            showPublishDialog: false,
        }
    }

    componentDidMount() {
        if (this.props.match.params.id !== Const.CreateNewDataUrl) {
            this.fetchData()
        }

        GetTags().then((result) => {
            this.setState({tags: result})
        })
    }

    componentDidUpdate(prevProps: Readonly<MatchProps>) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.fetchData()
        }
    }

    fetchData = () => {
        GetSalesMaterial(+this.props.match.params.id).then(
            (itemData) => {

                if (itemData.status === Status.published) {
                    this.context.setNotification(Notification.handleInfo(this.context.translate(translations.text.data_published_info)))
                }

                if (itemData.worksheet) {
                    GetWorksheetById(itemData.worksheet.id).then(
                        (worksheet) => {
                            itemData.worksheet = worksheet

                            GetWorksheetThumbnail(worksheet.id).then((worksheet) => {
                                this.setState({worksheetThumbnail: worksheet.thumbnail})
                            }, () => {
                                // Ignore error, when no thumbnail is available the default image is shown
                            })
                        }
                    )
                }

                this.setState({
                    item: itemData
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    isFormValid = () => {
        const form = document.getElementById("formData") as HTMLFormElement;
        return form && form.reportValidity()
    }

    onCopy = () => {
        if (this.isFormValid()) {

            this.onSave(false).then(
                () => {
                    CloneSalesMaterial(this.state.item, this.state.item.name + " - " + this.context.translate(translations.text_fragment.copy)).then(
                        (result: SalesMaterial) => {
                            this.setState({item: result})

                            this.props.history.push("/maintenance/marketplace-sales-material/" + result.id)
                        },
                        (error) => {
                            this.context.handleError(error, this.context.translate(translations.notification.copy_error))
                        }
                    )
                }
            )
        }
    }
    onCancelSave = () => {
        this.setState({showPublishDialog: false})
    }
    onSave = async (close: boolean) => {
        if (this.isFormValid()) {
            try {
                let currentItem = this.state.item

                if (currentItem.id) {
                    await UpdateSalesMaterial(this.state.item)

                    if (currentItem.worksheet && this.state.unsavedWorksheetChanges) {

                        // Added new collection, needs to be created
                        let tags: Tag[] = []
                        if (currentItem.worksheet.tags) {

                            tags = currentItem.worksheet.tags.filter(i => i.id !== undefined)
                            for (const i1 of currentItem.worksheet.tags.filter(i => i.id === undefined)) {
                                tags.push(await CreateTag(i1));
                            }
                        }
                        currentItem.worksheet.tags = tags

                        currentItem.worksheet = await UpdateWorksheet(currentItem.worksheet)

                        if (currentItem.status === Status.published && currentItem.worksheet.marketplaceStatus === WSMarketplaceStatus.approval) {
                            currentItem.worksheet = await PublishMPWorksheet(currentItem.worksheet.id)
                        }
                    }

                    this.setState({item: currentItem, unsavedChanges: false, unsavedWorksheetChanges: false}, () => {
                        if (close) {
                            this.onCancel()
                        }
                    })
                } else {
                    let result = await CreateSalesMaterial(this.state.item)
                    this.setState({item: result, unsavedChanges: false}, () => {
                        if (close) {
                            this.onCancel()
                        }
                    })
                }
                this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

            } catch (error) {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        }
    }
    onCancel = (path?: string) => {
        this.setState({unsavedChanges: false}, () =>
            this.props.history.push(path || "/maintenance/marketplace-sales-material")
        )
    }
    onSetState = (status: Status, user?: User): boolean => {
        if (this.state.item) {

            if (status === Status.published && !this.state.item.publishOnMP && !this.state.showPublishDialog) {
                this.setState({showPublishDialog: true})
                return false
            }

            const item = this.state.item
            SalesMaterial.setStatus(item, status, user)

            let worksheet = this.state.item.worksheet
            let unsavedWorksheetChanges = false

            if (status === Status.published && worksheet?.marketplaceStatus === WSMarketplaceStatus.approval) {
                // publishing takes place in save function
                unsavedWorksheetChanges = true
            }
            else if ((status === Status.approval && worksheet && this.state.item.publishOnMP) ||
                (status === Status.published && worksheet && worksheet.marketplaceStatus !== WSMarketplaceStatus.approval && this.state.item.publishOnMP)) {

                worksheet.marketplaceStatus = WSMarketplaceStatus.approval
                worksheet.marketplaceIsEditingAllowed = false
                worksheet.marketplaceShowEmail = true
                worksheet.marketplaceShowName = true

                unsavedWorksheetChanges = true
            }

            item.worksheet = worksheet

            this.setState({item: item, unsavedChanges: true, unsavedWorksheetChanges: unsavedWorksheetChanges, showPublishDialog: false}, () => this.onSave(false))
            return true
        }
        return false
    }
    onClickWorksheet = async () => {
        if (this.state.item.worksheet) {
            window.open(process.env.PUBLIC_URL + "/designer/" + this.state.item.worksheet.id)
        } else {
            // Create worksheet and open
            let worksheetToCreate = new Worksheet(1, this.state.item.name, WSContextType.sales_material, WSPageFormat.A4, true)
            try {
                let ws: Worksheet = await CreateWorksheet(worksheetToCreate)

                let p = new WorksheetPage(WorksheetPage.getNewPageKey(), false, PageBorderPosition.getDefaultPageBorder(), PageBorderPosition.getNoPageBorder(), 1)

                let page = await CreateWorksheetPage(p, ws.id)
                ws.pages = []
                ws.pages.push(page)

                const item = this.state.item
                item.worksheet = new Worksheet(ws.id, ws.name, ws.context, ws.format, ws.editingAllowed)
                this.setState({item: item})

                // save changes
                await this.onSave(false)

                // open worksheet
                window.open(process.env.PUBLIC_URL + "/designer/" + item.worksheet.id)
            } catch (error) {
                this.context.handleError(error, this.context.translate(translations.notification.saving_error))
            }
        }
    }

    onChangeSalesMaterialText = (value: string, fieldName: string) => {
        const item = this.state.item
        item[fieldName] = value
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeCheckbox = (field: string) => {
        let item = this.state.item
        item[field] = !item[field]
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeWorksheetTags = (tags: Tag[]) => {
        let item = this.state.item

        if (item.worksheet) {
            item.worksheet.tags = tags
            this.setState({item: item, unsavedChanges: true, unsavedWorksheetChanges: true})
        }
    }
    onChangeWorksheetText = (value: string, fieldName: string) => {
        let item = this.state.item

        if (item.worksheet) {
            item.worksheet[fieldName] = value
            this.setState({item: item, unsavedChanges: true, unsavedWorksheetChanges: true})
        }
    }
    onChangeWorksheetTarget = (field: string) => {
        let item = this.state.item

        if (item.worksheet) {
            item.worksheet[field] = !item.worksheet[field]
            this.setState({item: item, unsavedChanges: true, unsavedWorksheetChanges: true})
        }
    }

    isReadonly = () => {
        return this.state.item.status === Status.deactivated || (this.state.item.status === Status.approval && this.state.item.ownerId?.id !== Auth.getUserId())
    }
    isReadonlyWorksheet = () => {
        return this.isReadonly() || this.state.item.worksheet === undefined || this.state.item.worksheet === null
    }
    isTargetRequired = () => {
        return (!this.state.item.worksheet?.marketplaceTargetParents && !this.state.item.worksheet?.marketplaceTargetSchool &&
            !this.state.item.worksheet?.marketplaceTargetPupils && !this.state.item.worksheet?.marketplaceTargetTeacher && this.state.item.publishOnMP)
    }

    render() {
        return <>
            <AdminDataForm
            onSave={this.onSave}
            onCopy={this.onCopy}
            onCancel={this.onCancel}
            onSetState={this.onSetState}
            allowStatusChangeToUserItself={false}
            item={this.state.item}
            itemType={AdminObjectType.sales_material}
            hasUnsavedChanges={this.state.unsavedChanges}

            history={this.props.history}
            location={this.props.location}
            match={this.props.match}>

            <div className={"admin-form-content"}>

                <form id={"formData"} className="admin-form-input" onSubmit={() => {
                    return false
                }}>
                    <FormHeader
                        number={this.state.item.id}
                        status={this.state.item.status}
                        statusTranslationPath={translations.enum.status}
                        createdBy={this.state.item.createdBy}
                        ownerId={this.state.item.ownerId}
                    />

                    <div className="form-row">
                        <div className="form-group" style={{width: "500px", marginRight: "30px", paddingLeft: "0"}}>
                            <div className="form-row">
                                <TextBox id={"txtTitle"}
                                         width={500}
                                         label={this.context.translate(translations.fields.title)}
                                         required={false}
                                         readonly={this.isReadonly()}
                                         value={this.state.item.name}
                                         onChange={(value) => this.onChangeSalesMaterialText(value, "name")}
                                />
                            </div>

                            <div className="form-row">
                                <TextBox id={"txtDescription"}
                                         width={500}
                                         label={this.context.translate(translations.fields.description)}
                                         required={false}
                                         multiline={true}
                                         readonly={this.isReadonly()}
                                         value={this.state.item.description || ""}
                                         onChange={(value) => this.onChangeSalesMaterialText(value, "description")}
                                />
                            </div>

                            <div className="form-row">
                                <TextBox id={"txtMPStatus"}
                                         width={500}
                                         label={this.context.translate(translations.fields.mp_status)}
                                         required={false}
                                         readonly={true}
                                         value={this.state.item.worksheet?.marketplaceStatus
                                             ? this.context.translate(translations.enum.marketplace_ws_status[this.state.item.worksheet.marketplaceStatus])
                                             : ""}
                                />
                            </div>

                            <div className={"form-row"}>
                                <CheckBoxSwitch id={"chkPublishOnMP"}
                                                width={250}
                                                label={this.context.translate(translations.text.marketplace.send_to_marketplace)}
                                                readonly={this.isReadonlyWorksheet() || this.state.item.status === Status.published}
                                                required={false}
                                                checked={this.state.item.publishOnMP}
                                                onChange={() => this.onChangeCheckbox("publishOnMP")}
                                />
                            </div>

                            <div className={"form-group bold-label-gray"}>
                                {this.context.translate(translations.text.worksheet_settings.worksheet_settings)}
                            </div>

                            <div className="form-row">
                                <TextBox id={"txtWSTitle"}
                                         width={500}
                                         label={this.context.translate(translations.fields.title)}
                                         required={false}
                                         multiline={false}
                                         readonly={this.isReadonlyWorksheet()}
                                         value={this.state.item.worksheet?.name || ""}
                                         onChange={(value) => this.onChangeWorksheetText(value, "name")}
                                />
                            </div>

                            <div className="form-row">
                                <TextBox id={"txtWSDescription"}
                                         width={500}
                                         label={this.context.translate(translations.fields.description)}
                                         required={false}
                                         multiline={true}
                                         readonly={this.isReadonlyWorksheet()}
                                         value={this.state.item.worksheet?.description || ""}
                                         onChange={(value) => this.onChangeWorksheetText(value, "description")}
                                />
                            </div>

                            <div className="form-row">
                                <div className={"form-group"} style={{paddingLeft: 0}}>
                                    <CheckBoxSwitch id={"chkTargetPupil"}
                                                    width={250}
                                                    label={this.context.translate(translations.text.marketplace.target_pupils)}
                                                    readonly={this.isReadonlyWorksheet() || this.state.item.status === Status.published}
                                                    required={this.isTargetRequired()}
                                                    checked={this.state.item.worksheet?.marketplaceTargetPupils || false}
                                                    onChange={() => this.onChangeWorksheetTarget("marketplaceTargetPupils")}
                                    />
                                    <CheckBoxSwitch id={"chkTargetTeacher"}
                                                    width={250}
                                                    label={this.context.translate(translations.text.marketplace.target_teacher)}
                                                    readonly={this.isReadonlyWorksheet() || this.state.item.status === Status.published}
                                                    required={this.isTargetRequired()}
                                                    checked={this.state.item.worksheet?.marketplaceTargetTeacher || false}
                                                    onChange={() => this.onChangeWorksheetTarget("marketplaceTargetTeacher")}
                                    />
                                </div>
                                <div className={"form-group"}>
                                    <CheckBoxSwitch id={"chkTargetSchool"}
                                                    width={250}
                                                    label={this.context.translate(translations.text.marketplace.target_school)}
                                                    readonly={this.isReadonlyWorksheet() || this.state.item.status === Status.published}
                                                    required={this.isTargetRequired()}
                                                    checked={this.state.item.worksheet?.marketplaceTargetSchool || false}
                                                    onChange={() => this.onChangeWorksheetTarget("marketplaceTargetSchool")}
                                    />
                                    <CheckBoxSwitch id={"chkTargetParent"}
                                                    width={250}
                                                    label={this.context.translate(translations.text.marketplace.target_parents)}
                                                    readonly={this.isReadonlyWorksheet() || this.state.item.status === Status.published}
                                                    required={this.isTargetRequired()}
                                                    checked={this.state.item.worksheet?.marketplaceTargetParents || false}
                                                    onChange={() => this.onChangeWorksheetTarget("marketplaceTargetParents")}
                                    />
                                </div>
                            </div>

                            <div className={"form-row"}>
                                <TextBox id={"txtTags"}
                                         width={500}
                                         label={this.context.translate(translations.fields.image.tags)}
                                         required={false}
                                         readonly={this.isReadonlyWorksheet() || this.state.item.status === Status.published}
                                         data={this.state.tags}
                                         tags={this.state.item.worksheet?.tags || []}
                                         onChangeTags={this.onChangeWorksheetTags}
                                         mode={{autoComplete: true, tags: true}}
                                         value={""}
                                />
                            </div>
                        </div>

                        <div className={"form-group"} style={{width: "400px"}}>
                            {this.state.item.id && (
                                <div className={"admin-form-worksheet"}>
                                    <label className={"bold-label"}>Arbeitsmaterial</label>
                                    {this.state.worksheetThumbnail ?
                                        <img src={"data:image/jpeg;base64," + this.state.worksheetThumbnail}
                                             width={350}
                                             alt={this.context.translate(translations.text.worksheet)}
                                             onClick={this.onClickWorksheet}
                                             draggable={"false"}
                                             onContextMenu={(e) => e.preventDefault()}
                                        />
                                        :
                                        <img
                                            src={process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"}
                                            width={300} alt={this.context.translate(translations.text.worksheet)}
                                            onClick={this.onClickWorksheet}
                                            draggable={"false"}
                                            onContextMenu={(e) => e.preventDefault()}
                                        />
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </AdminDataForm>

        {this.state.showPublishDialog &&
        <Modal id={"saveDialog"}
               onFormSubmit={() => this.onSetState(Status.published)}
               onFormCancel={this.onCancelSave}
               title={this.context.translate(translations.text.data_published_without_mp_title)}
               buttons={[
                   new ButtonInfo("btnCancel", "button button-cancel", "button", false, false, this.context.translate(translations.command.cancel), this.onCancelSave, {}),
                   new ButtonInfo("btnSave", "button button-save", "submit", true, false, this.context.translate(translations.command.ok), undefined, {marginLeft: "10px"})
               ]}
               dialogStyle={{width: "35%", height: "300px", minWidth: "400px"}}
               contentAlignment={"flex-start"}
        >
            <div style={{whiteSpace: "break-spaces"}}> {this.context.translate(translations.text.data_published_without_mp)} </div>
        </Modal>
        }
        </>
    }
}

