import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Const from "../../Framework/Const";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import LessonSubject from "../../_model/LessonSubject";
import {CreateTag, GetLessonSubject, GetSchoolLevel, GetTags} from "../../_endpoint/MetadataEndpoint";
import SchoolLevel from "../../_model/SchoolLevel";
import TextBox from "../../Components/Controls/TextBox";
import {
    CloneWorksheet,
    CreateWorksheet,
    CreateWorksheetPage,
    GetWorksheetThumbnail,
    SaveWorksheetItem
} from "../../_endpoint/WorksheetEndpoint";
import {Worksheet} from "../../_model/Worksheet";
import {WorksheetItem} from "../../_model/WorksheetItem";
import {
    AdminObjectType,
    Difficulty,
    EnumToEntityArray,
    EnumValueToValueNumber,
    ExerciseCalculationType,
    ExerciseEpicType,
    ExerciseTextType,
    ExerciseType,
    Status,
    WSPageFormat
} from "../../Framework/Enums";
import {Notification} from "../../Components/Notification/NotificationHandler";
import {MainContext} from "../../_base/MainContext";
import AdminDataForm from "../Maintenance/AdminDataForm";
import Tag from "../../_model/Tag";
import Exercise from "../../_model/Exercise";
import {
    CloneExercise,
    CreateExercise,
    GetExercise,
    GetExerciseCategories,
    UpdateExercise
} from "../../_endpoint/ExerciseEndpoint";
import DualList from "../../Components/Controls/DualList";
import {GetThirdLayerToolboxItemsByExerciseType, GetToolboxItemsBySubject} from "../../_endpoint/MenuEndpoint";
import SelectBox from "../../Components/Controls/SelectBox";
import ExerciseCategory from "../../_model/ExerciseCategory";
import {PageBorderPosition} from "../../Components/PageBorder/WDPageBorder";
import {TreeView} from "../../Components/Controls/TreeView";
import User from "../../_model/User";
import FormHeader from "../../Components/Form/FormHeader";
import Auth from "../../Framework/Auth";
import CheckBoxSwitch from "../../Components/Controls/CheckBoxSwitch";
import Converter from "../../Framework/Converter";
import {WSContextType} from "../../Designer/Elements/WSContext";
import {WorksheetItemTypeEnum} from "../../_model/WorksheetItemType";
import {GetWordHighlights} from "../../_endpoint/WordEndpoint";
import WordHighlight from "../../_model/Dictionary/WordHighlight";
import {MenuExerciseType} from "../../Components/Menu/Menu";
import {WorksheetPage} from "../../_model/WorksheetPage";
import _ from "lodash";
import {ToolboxElement, ToolboxGroup, ToolboxGroupElements} from "../../Components/Menu/ToolboxElement";
import {MenuItem} from "../../_model/MenuItem";

interface MatchParams {
    id: string
}

export interface MatchProps extends RouteComponentProps<MatchParams> { }

interface IState {
    item: Exercise
    unsavedChanges: boolean
    type: ExerciseType

    worksheetThumbnail?: string
    lessonSubjects: LessonSubject[]
    exerciseCategories: ExerciseCategory[]
    schoolLevels: SchoolLevel[]
    tags?: Tag[]

    menuItems: MenuItem[]
    menuItemsSelected: number[]
    wordHighlights: any[]
    wordHighlightsSelected: any[]
}

export default class ExerciseForm extends React.Component<MatchProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    menuEntries: ToolboxGroup[] = []
    wordHighlights: WordHighlight[] = []

    constructor(props: MatchProps, state: IState) {
        super(props, state)

        let item = new Exercise("", Status.draft)
        if(this.isNew() && this.props.match.params.id !== undefined && this.props.match.params.id !== "new") {
            this.context.log.info("New subtask for " + this.props.match.params.id)

            item.parentExercise = new Exercise("", Status.draft)
            item.parentExercise.id = +this.props.match.params.id

            this.context.log.flush()
        }

        this.state = {
            type: ExerciseType.normal,
            item: item,
            unsavedChanges: false,
            lessonSubjects: [],
            exerciseCategories: [],
            schoolLevels: [],
            menuItems: [],
            menuItemsSelected: [],
            wordHighlights: [],
            wordHighlightsSelected: []
        }
    }

    componentDidMount() {
        this.initExercise()
    }
    componentDidUpdate(prevProps: Readonly<MatchProps>) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.initExercise()
        }
    }

    initExercise = () => {
        let exerciseType: ExerciseType = ExerciseType.normal

        // Identify text exercises by URL path
        if (this.props.match.path.includes("text-exercise")) {
            exerciseType = ExerciseType.text_exercise
        } else if (this.props.match.path.includes("exercise-pattern")) {
            exerciseType = ExerciseType.exercise_pattern
        }
        this.context.log.info("Exercise type = " + exerciseType)
        this.context.log.info("ID = " + this.props.match.params.id)
        this.context.log.flush()

        if(!this.isNew()) {
            this.fetchData()
            this.setState({type: exerciseType}, () => this.fetchLists())

        } else if (this.isNew() && exerciseType === ExerciseType.text_exercise && this.state.item.parentExercise && this.state.item.parentExercise.id) {
            GetExercise(this.state.item.parentExercise.id).then(
                (itemData) => {
                    let itemWithParentData = this.state.item
                    itemWithParentData.parentExercise = itemData
                    this.setState({type: exerciseType, item: itemWithParentData }, () => this.fetchLists())
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                }
            )
        } else {
            // not text exercise and new OR new text exercise but no parent
            this.setState({type: exerciseType}, () => this.fetchLists())
        }
    }

    fetchData = () => {
        GetExercise(+this.props.match.params.id).then(
            (itemData) => {

                this.context.log.info("Parent Exercise = " + itemData.parentExercise)

                if(itemData.status === Status.published) {
                    this.context.setNotification(Notification.handleInfo(this.context.translate(translations.text.data_published_info)))
                }

                if (itemData.worksheet) {
                    GetWorksheetThumbnail(itemData.worksheet.id).then((worksheet) => {
                        this.setState({worksheetThumbnail: worksheet.thumbnail})
                    }, () => {
                        // Ignore error, when no thumbnail is available the default image is shown
                    })
                }

                if(itemData.lessonSubject && itemData.lessonSubject.id) {
                    this.mapToolboxItemsToEntries(itemData.lessonSubject.id, itemData.exerciseCategory?.id)

                    GetExerciseCategories(itemData.lessonSubject.id).then((categories) => {
                        categories = this.filterCategoriesByPattern(categories)
                        this.setState({exerciseCategories: categories})
                    }, (error) => {
                        this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                    })
                }

                if(itemData.parentExercise) {
                    this.context.log.info("Get parent exercise " + itemData.parentExercise.id)

                    GetExercise(itemData.parentExercise.id!).then((parent) => {
                        let data = this.state.item
                        data.parentExercise = parent
                        this.setState({item: itemData})
                    }, (error) => {
                        this.context.handleError(error, this.context.translate(translations.notification.loading_error))
                    })
                }

                this.context.log.flush()

                this.setState({
                    item: itemData,
                    menuItemsSelected: itemData.menuEntries ? itemData.menuEntries.map(entry => {return entry.id}) : [],
                    wordHighlightsSelected: itemData.wordHighlights ? itemData.wordHighlights.map(entry => {return entry.id}) : []
                })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }
    fetchLists = () => {
        GetLessonSubject().then((result) => {
            this.context.log.info("Exercise type = " + this.state.type)

            if (this.state.type === ExerciseType.text_exercise) {
                this.context.log.info("Find lesson subject 'math'")

                let math = result.find(l => l.code === "MA")
                if (math) {
                    this.context.log.info("Set lesson subject")
                    this.setState({lessonSubjects: [math]}, () => {
                        if (this.isNew()) {
                            this.onChangeLessonSubject(math!.id!)
                        }
                    })
                }
            }
            else {
                this.setState({lessonSubjects: result})
            }
            this.context.log.flush()
        }, (error) => {
            this.context.handleError(error, this.context.translate(translations.notification.loading_error))
        })
        GetSchoolLevel().then((result) => {this.setState({schoolLevels: result })}, (error) => {
            this.context.handleError(error, this.context.translate(translations.notification.loading_error))
        })
        GetTags().then((result) => { this.setState({ tags: result })}, (error) => {
            this.context.handleError(error, this.context.translate(translations.notification.loading_error))
        })
        GetWordHighlights().then((result) => {
            this.wordHighlights = result

            this.setState({wordHighlights: result.map((entry) => {
                return {value: entry.id, label: entry.name}})
            })
        }, (error) => {
            this.context.handleError(error, this.context.translate(translations.notification.loading_error))
        })
    }

    mapToolboxItemsToEntries = (lessonSubjectId: number, exerciseCategoryId?: number) => {
        if(this.isPattern()) {
            GetThirdLayerToolboxItemsByExerciseType(MenuExerciseType.pattern).then((menuEntries) => {
                this.setMenuItemsAsToolboxItems(menuEntries)
            }, (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            })
        } else {
            GetToolboxItemsBySubject(lessonSubjectId).then((menuEntries) => {
                this.setMenuItemsAsToolboxItems(menuEntries)
            }, (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            })
        }
    }
    setMenuItemsAsToolboxItems = (menuEntries: MenuItem[]) => {
        let elements = menuEntries.map(i => new ToolboxElement( i.id, i.name, i.parentId?.name ))
        let groupedElements = _.groupBy(elements, "parent")

        let data: ToolboxGroup[] = []
        Object.keys(groupedElements).forEach(k => {
            let o =  new ToolboxGroup(k)
            groupedElements[k].map(i => o.options.push(new ToolboxGroupElements(i.id.toString(), i.name)))
            data.push(o)
        })

        this.menuEntries = data

        this.setState({menuItems: menuEntries})
    }

    getWorksheetName = () => {
        return ("WS-Exercise: " + this.state.item.name).substr(0, 100)
    }

    isFormValid = () => {
        const form1 = document.getElementById("formData1")  as HTMLFormElement;
        const form2 = document.getElementById("formData2")  as HTMLFormElement;
        return form1 && form1.reportValidity() && form2 && form2.reportValidity()
    }

    onSave = async (close: boolean) => {
        if (this.isFormValid()) {

            try {
                let currentItem = this.state.item

                //Remove difficulty none - otherwise it has problems to save as none is not a valid difficulty status
                if(this.state.item.difficulty === Difficulty.none) {
                    currentItem.difficulty = undefined
                }

                //Set selection as menuEntries for item
                if(this.state.menuItemsSelected) {
                    let saveItem = this.state.item
                    saveItem.menuEntries = this.state.menuItems.filter(m => this.state.menuItemsSelected.includes(m.id))
                }

                //Set selection as menuEntries for item
                if(this.state.wordHighlightsSelected) {

                    let saveItem = this.state.item
                    saveItem.wordHighlights = this.wordHighlights.filter (
                        item => this.state.wordHighlightsSelected.find(entry => entry === item.id) !== undefined
                    )
                }

                // Added new collection, needs to be created
                let tags: Tag[] = []
                if (currentItem.tags) {
                    tags = currentItem.tags.filter(i => i.id !== undefined)
                    for (const i1 of currentItem.tags.filter(i => i.id === undefined)) {
                        tags.push(await CreateTag(i1));
                    }
                }
                currentItem.tags = tags

                if (currentItem.id) {
                    await UpdateExercise(this.state.item)
                    this.setState({unsavedChanges: false}, () => {
                        if (close) {
                            this.onCancel()
                        }
                    })
                }
                else {
                    let result = await CreateExercise(currentItem)
                    result.parentExercise = this.state.item.parentExercise

                    this.setState({item: result, unsavedChanges: false}, () => {
                        if (close) {
                            this.onCancel()
                        }
                    })
                }
                this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))

            } catch (error) {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        }
    }
    onCancel = (path?: string) => {
        this.setState({unsavedChanges: false}, () =>
            this.props.history.push(path || this.getBackUrl())
        )
    }
    onCopy = () => {
        if (this.isFormValid()) {

            this.onSave(false).then(
                () => {
                    let context = WSContextType.exercise
                    if (this.isTextExercise() && this.hasParent()) {
                        context = WSContextType.text_exercise_child
                    }
                    else if (this.isTextExercise() && !this.hasParent()) {
                        context = WSContextType.text_exercise_main
                    }

                    CloneExercise(this.state.item, this.state.item.name + " - " + this.context.translate(translations.text_fragment.copy), context).then(
                    (result: Exercise) => {
                        this.setState({item: result})

                        this.props.history.push(this.getBackUrl() + result.id)
                    },
                    (error) => {
                        this.context.handleError(error, this.context.translate(translations.notification.copy_error))
                    }
                )}
            )
        }
    }
    onSetState = (status: Status, user?: User) => {
        if(this.state.item) {
            const item = this.state.item
            Exercise.setStatus(item, status, user)
            this.setState({item: item}, () => this.onSave(false))
        }
    }
    onClickWorksheet = async () => {
        if (this.state.item.worksheet) {
            window.open(process.env.PUBLIC_URL + "/designer/" + this.state.item.worksheet.id)
        }
        else {
            let ws: Worksheet | undefined = undefined

            this.context.log.debug("isTextExercise = " + this.isTextExercise())
            this.context.log.debug("hasParent = " + this.hasParent())
            this.context.log.debug("parentExercise", this.state.item.parentExercise)

            // Clone worksheet of parent exercise if it is a clone text exercise
            if (this.isTextExercise() && this.hasParent() && this.state.item.parentExercise && this.state.item.parentExercise.worksheet) {
                let worksheetId = this.state.item.parentExercise.worksheet?.id

                this.context.log.info("Cloning worksheet " + worksheetId)
                ws = await CloneWorksheet(worksheetId, this.getWorksheetName(), WSContextType.text_exercise_child)
            }
            else if (this.isTextExercise()) {
                // Create worksheet with exercise tool and open
                ws = await this.createWorksheet(WSContextType.text_exercise_main, true)
            }
            else if (this.isPattern()) {
                // Create worksheet without exercise tool and open
                ws = await this.createWorksheet(WSContextType.exercise_pattern, false)
            }
            else {
                ws = await this.createWorksheet(WSContextType.exercise, true)
            }

            if (ws === undefined) {
                return
            }

            // Save link to newly created worksheet
            const item = this.state.item
            item.worksheet = new Worksheet(ws.id, ws.name, ws.context, ws.format, ws.editingAllowed)
            this.setState({item: item})

            // save changes
            this.onSave(false)

            // open worksheet
            window.open(process.env.PUBLIC_URL + "/designer/" + item.worksheet.id)

            this.context.log.flush()
        }
    }
    createWorksheet = async (exerciseType: WSContextType, addExerciseTool: boolean) => {
        // Create worksheet with exercise tool and open
        let worksheetToCreate = new Worksheet(1, this.getWorksheetName(), exerciseType, WSPageFormat.A4, true)
        try {
            let ws = await CreateWorksheet(worksheetToCreate)

            // Create worksheet page
            let p = new WorksheetPage(WorksheetPage.getNewPageKey(), false, PageBorderPosition.getDefaultPageBorder(), PageBorderPosition.getNoPageBorder(), 1)

            let page = await CreateWorksheetPage(p, ws.id)
            ws.pages = []
            ws.pages.push(page)

            if(addExerciseTool) {
                // Create text exercise worksheet item
                let worksheetItem = new WorksheetItem("text-exercise-" + Date.now(),
                    WorksheetPage.getUniqueElementIdentifier(page), WorksheetItemTypeEnum.TEXT_EXERCISE, Converter.toMmGrid(30),
                    Converter.toMmGrid(30), Const.WorksheetDefaultPageWidth - 60, 540, "{}",
                    true, false, false, false)

                await SaveWorksheetItem(worksheetItem, ws.id, page.id!)
            }

            return ws

        } catch (error) {
            this.context.handleError(error, this.context.translate(translations.notification.saving_error))
        }
    }
    onOpenMainExercise = () => {
        this.props.history.push("/maintenance/text-exercises/" + this.state.item.parentExercise?.id)
    }

    onChangeExerciseTitle = (value: string) => {
        const item = this.state.item
        item.name = value
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeExerciseDescription = (value: string) => {
        const item = this.state.item
        item.description = value
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeDifficulty = (value: number) => {
        // Get enum value by index (value)
        let difficulty = Object.values(Difficulty).find((e, i) => i === value)
        if (difficulty) {
            let currentItem = this.state.item
            currentItem.difficulty = difficulty
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeFlagComma = () => {
        const item = this.state.item
        item.flagComma = !item.flagComma
        this.setState({item: item, unsavedChanges: true})
    }
    onChangeCalculationType = (value: number) => {
        // Get enum value by index (value)
        let calculationType = Object.values(ExerciseCalculationType).find((e, i) => i === value)
        if (calculationType) {
            let currentItem = this.state.item
            currentItem.calculationType = calculationType
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeTextType = (value: number) => {
        // Get enum value by index (value)
        let textType = Object.values(ExerciseTextType).find((e, i) => i === value)
        if (textType) {
            let currentItem = this.state.item
            currentItem.textType = textType
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeEpicType = (value: number) => {
        // Get enum value by index (value)
        let epicType = Object.values(ExerciseEpicType).find((e, i) => i === value)
        if (epicType) {
            let currentItem = this.state.item
            currentItem.epicType = epicType
            this.setState({item: currentItem, unsavedChanges: true})
        }
    }
    onChangeSchoolLevel = (selected: SchoolLevel[]) => {
        const item = this.state.item

        item.schoolLevels = selected.filter((item, i, array) => {
            return item.parent === null || array.find(it => it.id === item.parent?.id) !== undefined
        })

        this.setState({item: item, unsavedChanges: true})
    }
    onChangeLessonSubject = async (value: number) => {
        const item = this.state.item
        item.lessonSubject = this.state.lessonSubjects.find(i => i.id === value)

        // menuItems are specifically loaded depending on lesson subject
        this.mapToolboxItemsToEntries(value)

        // categories are specifically loaded depending on lesson subject
        let categories = await GetExerciseCategories(value)
        if(item.exerciseCategory && !categories.find(i => i.id === item.exerciseCategory?.id)) {
            item.exerciseCategory = undefined
        }
        if (this.state.type === ExerciseType.text_exercise) {
            item.exerciseCategory = categories.find(i => i.type === ExerciseType.text_exercise)
        }

        categories = this.filterCategoriesByPattern(categories);

        this.setState({item: item, unsavedChanges: true, exerciseCategories: categories})
    }
    onChangeMenuEntries = (selected) => {
        this.setState({ menuItemsSelected: selected.map(s => +s), unsavedChanges: true});
    }
    onChangeWordHighlights = (selected) => {
        this.setState({ wordHighlightsSelected: selected , unsavedChanges: true});
    }
    onChangeExerciseCategory = (value: number) => {
        let item = this.state.item
        item.exerciseCategory = this.state.exerciseCategories.find(i => i.id === value)

        if(this.isPattern() && item.lessonSubject && item.lessonSubject.id) {
            this.mapToolboxItemsToEntries(item.lessonSubject.id, value)
        }

        this.setState({item: item})
    }
    onChangeTags = (tags: Tag[]) => {
        let item = this.state.item
        item.tags = tags
        this.setState({item: item, unsavedChanges: true})
    }

    filterCategoriesByPattern = (categories: ExerciseCategory[]) : ExerciseCategory[] => {
        return categories.filter(cat => this.isPattern()
            ? cat.type === ExerciseType.exercise_pattern
            : cat.type !== ExerciseType.exercise_pattern)
    }

    getBackUrl = () => {
        if (this.state.type === ExerciseType.text_exercise) {
            if (this.hasParent()) {
                return "/maintenance/text-exercises/" + this.state.item.parentExercise!.id + "/list"
            }

            if (this.isNew()) {
                return "/maintenance/text-exercises/"
            }

            return "/maintenance/text-exercises/" + this.state.item.id + "/list"
        }
        else if (this.state.type === ExerciseType.exercise_pattern) {
            return "/maintenance/exercise-pattern/"
        }
        return "/maintenance/exercises/"
    }
    getAuthor = () => {
        if (this.state.item.createdBy) {
            return this.state.item.createdBy?.firstname + " " + this.state.item.createdBy?.lastname
        }

        return ""
    }

    isMath = () => {
        return this.state && this.state.item.lessonSubject?.code === "MA"
    }
    isGerman = () => {
        return this.state && this.state.item.lessonSubject?.code === "D"
    }
    isReadonly = () => {
        return this.state.item.status === Status.deactivated || (this.state.item.status === Status.approval && this.state.item.ownerId?.id !== Auth.getUserId())
    }
    isTextExercise = () => {
        return this.state.type === ExerciseType.text_exercise
    }
    isPattern = () => {
        return this.state.type === ExerciseType.exercise_pattern
    }
    isNew = () => {
        return (this.props.location.pathname.endsWith(Const.CreateNewDataUrl))
    }
    hasParent = () => {
        return this.state && this.state.item && this.state.item.parentExercise !== undefined && this.state.item.parentExercise !== null
    }

    render() {
        let itemType = AdminObjectType.exercise
        if (this.isTextExercise()) {
            itemType = AdminObjectType.text_exercise_main
            if (this.hasParent()) {
                itemType = AdminObjectType.text_exercise_child
            }
        } else if (this.isPattern()) {
            itemType = AdminObjectType.exercise_pattern
        }

        return <AdminDataForm
            onSave={this.onSave}
            onCopy={this.onCopy}
            onCancel={this.onCancel}
            onSetState={this.onSetState}
            allowStatusChangeToUserItself={false}
            item={this.state.item}
            itemType={itemType}
            hasUnsavedChanges={this.state.unsavedChanges}

            history={this.props.history}
            location={this.props.location}
            match={this.props.match}>

            <div className={"admin-form-content"}>

            <form id={"formData1"} className="admin-form-input" onSubmit={() => { return false} }>

                <FormHeader
                    number={this.state.item.id}
                    status={this.state.item.status}
                    statusTranslationPath={translations.enum.status}
                    createdBy={this.state.item.createdBy}
                    ownerId={this.state.item.ownerId}
                />

                {this.state.item.parentExercise &&
                    <div className="form-group form-subheader">
                        <div className={"form-subheader-title"} onClick={this.onOpenMainExercise}>
                            {this.context.translate(translations.text.main_exercise)}: {this.state.item.parentExercise.name}
                        </div>
                        <div className={"form-subheader-description"}>
                            {this.state.item.parentExercise.description}
                        </div>
                    </div>
                }

                <div className={"form-row"}>
                    <CheckBoxSwitch id={"chkPattern"}
                                    label={this.context.translate(translations.fields.exercise.pattern)}
                                    checked={this.isPattern()}
                                    readonly={true}
                    />
                </div>

                <div className="form-row">
                    <div className="form-group" style={{width: "500px", paddingRight: "10px", paddingLeft: "0"}}>
                        <div className="form-row">
                            <SelectBox id={"selLessonSubject"}
                                       label={this.context.translate(translations.fields.lesson_subject)}
                                       width={484}
                                       style={{marginRight: "10px"}}
                                       data={this.state.lessonSubjects}
                                       onChange={this.onChangeLessonSubject}
                                       value={this.state.item.lessonSubject?.id}
                                       required={true}
                                       readonly={this.isReadonly() || this.isTextExercise()}
                                       positioningRow={false}
                            />
                        </div>

                        <div className="form-row">
                            <TextBox id={"txtTitle"}
                                     width={500}
                                     label={this.context.translate(translations.fields.title)}
                                     required={true}
                                     readonly={this.isReadonly()}
                                     onChange={this.onChangeExerciseTitle}
                                     value={this.state.item.name}
                            />
                        </div>

                        <div className="form-row">
                            <TextBox id={"txtDescription"}
                                     width={500}
                                     label={this.context.translate(translations.fields.description)}
                                     required={true}
                                     multiline={true}
                                     readonly={this.isReadonly()}
                                     onChange={this.onChangeExerciseDescription}
                                     value={this.state.item.description || ""}
                            />
                        </div>

                        {(!this.isTextExercise() || this.hasParent()) &&
                            <div className="form-row">
                                <SelectBox id={"selDifficulty"}
                                           width={484}
                                           label={this.context.translate(translations.fields.exercise.difficulty)}
                                           data={EnumToEntityArray(Difficulty, translations.enum.difficulty, this.context.translate)}
                                           required={!this.isPattern()}
                                           value={EnumValueToValueNumber(Difficulty, this.state.item.difficulty)}
                                           readonly={this.isReadonly()}
                                           onChange={this.onChangeDifficulty}
                                           positioningRow={false}
                                />
                            </div>
                        }

                        {(this.isGerman()) &&
                            <>
                                <div className="form-row">
                                    <SelectBox id={"selTextType"}
                                               width={484}
                                               label={this.context.translate(translations.fields.exercise.text_type)}
                                               data={EnumToEntityArray(ExerciseTextType, translations.enum.exercise_text_type, this.context.translate)}
                                               required={true}
                                               value={EnumValueToValueNumber(ExerciseTextType, this.state.item.textType)}
                                               readonly={this.isReadonly()}
                                               onChange={this.onChangeTextType}
                                               positioningRow={false}
                                    />
                                </div>

                                <div className="form-row">
                                    <SelectBox id={"selEpicType"}
                                               width={484}
                                               label={this.context.translate(translations.fields.exercise.epic_type)}
                                               data={EnumToEntityArray(ExerciseEpicType, translations.enum.exercise_epic_type, this.context.translate)}
                                               required={true}
                                               value={EnumValueToValueNumber(ExerciseEpicType, this.state.item.epicType)}
                                               readonly={this.isReadonly()}
                                               onChange={this.onChangeEpicType}
                                               positioningRow={false}
                                    />
                                </div>
                            </>
                        }

                        {(this.isMath() && this.isTextExercise() && this.hasParent()) &&
                            <div className="form-row">
                                <CheckBoxSwitch id={"chkFlagComma"}
                                                width={484}
                                                label={this.context.translate(translations.fields.exercise.flagComma)}
                                                checked={this.state.item.flagComma}
                                                readonly={this.isReadonly()}
                                                onChange={this.onChangeFlagComma}
                                />
                            </div>
                        }
                        {(this.isMath() && this.isTextExercise() && !this.hasParent()) &&
                            <div className="form-row">
                                <SelectBox id={"selCalculationType"}
                                           width={484}
                                           label={this.context.translate(translations.fields.calculation_type)}
                                           data={EnumToEntityArray(ExerciseCalculationType, translations.enum.exercise_calculation_type, this.context.translate)}
                                           required={true}
                                           value={EnumValueToValueNumber(ExerciseCalculationType, this.state.item.calculationType)}
                                           readonly={this.isReadonly()}
                                           onChange={this.onChangeCalculationType}
                                           positioningRow={false}
                                />
                            </div>
                        }

                        {!this.hasParent() &&
                            <div className="form-row">
                                <TreeView
                                    id={"lstSubSchoolLevel"}
                                    label={this.context.translate(translations.fields.exercise.school_level)}
                                    data={this.state.schoolLevels}
                                    selectedData={this.state.item?.schoolLevels || []}
                                    required={false}
                                    readonly={this.isReadonly()}
                                    parent={"parent"}
                                    onChange={this.onChangeSchoolLevel}
                                />
                            </div>
                        }
                    </div>

                    <div className={"form-group"}>
                        <div className="form-row">
                            <SelectBox id={"selExerciseCategory"}
                                       label={this.context.translate(translations.fields.exercise.category)}
                                       width={484}
                                       style={{marginRight: "10px"}}
                                       data={this.state.exerciseCategories}
                                       onChange={this.onChangeExerciseCategory}
                                       value={this.state.item.exerciseCategory?.id}
                                       required={true}
                                       readonly={this.isReadonly() || this.isTextExercise()}
                                       positioningRow={false}
                            />
                        </div>

                        {this.state.item.id && (
                            <div className={"admin-form-worksheet"}>
                                {this.state.worksheetThumbnail ?
                                    <img src={"data:image/jpeg;base64," + this.state.worksheetThumbnail}
                                         width={350}
                                         alt={this.context.translate(translations.text.worksheet)}
                                         onClick={this.onClickWorksheet}
                                         draggable={"false"}
                                         onContextMenu={(e) => e.preventDefault() }
                                    />
                                    :
                                    <img src={process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"}
                                         width={350}
                                         alt={this.context.translate(translations.text.worksheet)}
                                         onClick={this.onClickWorksheet}
                                         draggable={"false"}
                                         onContextMenu={(e) => e.preventDefault() }
                                    />
                                }
                            </div>
                        )}

                    </div>
                </div>
            </form>

            {/* Menu level assignment if not main text exercise or pattern */}
            {(!this.isTextExercise() || this.hasParent()) &&
                <DualList
                    options={this.menuEntries}
                    selected={this.state.menuItemsSelected.map(s => s.toString())}
                    width={1000}
                    marginTop={0}
                    marginLeft={0}
                    disabled={this.isReadonly()}
                    label={this.context.translate(translations.fields.exercise.menuRelation)}
                    onChange={this.onChangeMenuEntries}
                />
            }

            {(this.isGerman()) &&
                <DualList
                    options={this.state.wordHighlights}
                    selected={this.state.wordHighlightsSelected}
                    width={1000}
                    marginTop={0}
                    marginLeft={0}
                    disabled={this.isReadonly()}
                    label={this.context.translate(translations.fields.exercise.wordHighlights)}
                    onChange={this.onChangeWordHighlights}
                />
            }

            <form id={"formData2"} className="admin-form-input" onSubmit={() => { return false} }>
                <div className="form-row">

                    <div className={"form-row"}>
                        {(!this.isTextExercise() || this.hasParent()) &&
                            <TextBox id={"txtTags"}
                                     width={800}
                                     label={this.context.translate(translations.fields.image.tags)}
                                     required={false}
                                     readonly={this.isReadonly()}
                                     data={this.state.tags}
                                     tags={this.state.item.tags || []}
                                     onChangeTags={this.onChangeTags}
                                     mode={{autoComplete: true, tags: true}}
                                     value={""}
                            />
                        }
                        </div>
                    </div>

                    <div className={"form-row"} style={{height: "200px"}}/>

                </form>
            </div>
        </AdminDataForm>
    }
}

