import React from "react";
import {Coords} from "../../Framework/Coords";
import {MainContext} from "../../_base/MainContext";
import {ImagePath} from "../../Framework/CategoryImage";
import WDContextMenuGroup from "./WDContextMenuGroup";

export interface WDContextMenuProps {
    id: string,
    left: number,
    top: number,
    isLocked: boolean
    className?: string

    groups: WDContextMenuGroup[]
}

export interface WDContextMenuState {
    currentItem: number
}

export class WDContextMenu extends React.Component<WDContextMenuProps, WDContextMenuState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    L = this.context.log

    constructor(props: WDContextMenuProps, state: WDContextMenuState) {
        super(props, state);

        this.state = {
            currentItem: -1
        }
    }

    componentDidMount() {
        // Update again after render to get the correct bounding rect
        this.forceUpdate()
    }

    getBoundingRect = () => {
        let rect = new DOMRect(0, 0, 0, 0)

        // calculate position
        let toolbar = document.getElementById("context-menu-" + this.props.id);
        if (toolbar) {
            rect = toolbar.getBoundingClientRect()
        }

        rect.width = Math.round(rect.width)
        rect.height = Math.round(rect.height)

        return rect
    }
    getMenuPosition = (): Coords => {
        let rect = this.getBoundingRect()

        // Calculate toolbar coordinates
        rect.x = Math.max(this.props.left, 0)
        rect.y = Math.max(this.props.top, 0)

        if (rect.x + rect.width > window.innerWidth) {
            rect.x = window.innerWidth - rect.width
        }
        if (rect.y + rect.height > window.innerHeight) {
            rect.y = window.innerHeight - rect.height
        }

        return new Coords(rect.x, rect.y)
    }

    onMouseEnterItem = (id: number) => {
        if (this.state && this.state.currentItem !== id) {
            this.context.log.info("Context menu: Set current item = " + id)
            this.context.log.flush()

            this.setState({currentItem: id})
        }
    }
    onMouseLeaveItem = (id: number) => {
        // if (this.state && this.state.currentItem !== -1) {
        //     this.context.log.info("Context menu: Set current item = " + id)
        //     this.context.log.flush()
        //
        //     this.setState({currentItem: -1})
        // }
    }
    
    renderGroup = (id: string, group: WDContextMenuGroup, top: number, last: boolean) => {
        let className = "ws-designer-context-menu-group"
        if (last) {
            className += " ws-designer-context-menu-group-last"
        }

        return <div id={id + "-group-" + group.id} key={group.id} className={className}>
            {group.items.map(item => {
                return <div id={id + "-item-" + item.id}
                     key={"context-menu-item-" + item.id}
                     className={item.disabled ? "ws-designer-context-menu-item-disabled" : "ws-designer-context-menu-item"}
                     onMouseEnter={() => (item.disabled ? () => {} : this.onMouseEnterItem(item.id))}
                     onMouseLeave={() => (item.disabled ? () => {} : this.onMouseLeaveItem(item.id))}
                     onClick={() => (item.disabled ? () => {} : item.action?.())}
                >
                    <div className={"ws-designer-context-menu-item-icon-container"}>
                        {item.icon &&
                            <img
                                src={process.env.PUBLIC_URL + item.icon}
                                className={"ws-designer-context-menu-item-icon " + (item.disabled ? "svg-color-disabled" : "svg-color-primary")}
                                alt={item.name}
                                draggable={"false"}
                                onContextMenu={(e) => e.preventDefault()}
                            />
                        }
                    </div>

                    <div className={"ws-designer-context-menu-item-name"}>{item.name}</div>
                    {item.shortcut &&
                        <div className={"ws-designer-context-menu-item-shortcut"}>{item.shortcut}</div>
                    }

                    {item.children &&
                        <div className={"ws-designer-context-menu-item-shortcut"}>
                            <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_next.svg"}
                                 className={"svg-color-primary"}
                                 style={{width: 8}}
                                 alt={"Sub-Menu"}
                            />
                        </div>
                    }

                    {item.children && this.state && this.state.currentItem === item.id &&
                        <WDContextMenu id={id + "-" + item.id + "-items"}
                                       left={240}
                                       top={top}
                                       isLocked={false}
                                       groups={[item.children]}
                        />
                    }
                </div>
            })}
        </div>
    }
    render () {
        const id = "context-menu-" + this.props.id

        let className = "ws-designer-context-menu"
        if (this.props.className) {
            className += " " + this.props.className
        }

        const coords = this.getMenuPosition()

        return <div id={id} className={className}
                    style={{left: coords.x, top: coords.y}}
        >
            {this.props.groups.map((g, index) => {
                return this.renderGroup(id, g, 0, index === this.props.groups.length - 1)
            })}
        </div>
    }
}
