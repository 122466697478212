import {SidebarContentBaseListFilter} from "../SidebarContentBase";
import {ImageAlignment, ImageBackground, ImageColor, ImageLicense, ImageProperty} from "../../../_model/Image";
import {FrameType} from "./SidebarFrames";
import {BorderStyle} from "../../../Components/Controls/BorderStylingOptions";
import {PageBorderPosition} from "../../../Components/PageBorder/WDPageBorder";
import {WorksheetPage} from "../../../_model/WorksheetPage";

export class FrameImageListFilter extends SidebarContentBaseListFilter {
    originatorId: number
    collectionId: number
    alignments: ImageAlignment[]
    properties: ImageProperty[]
    colors: ImageColor[]
    backgrounds: ImageBackground[]
    licenses: ImageLicense[]
    pageFrame?: boolean

    frameType: FrameType
    borderRadius: number
    borderTransparency: number
    borderOptions: PageBorderPosition
    borderStyle: BorderStyle
    borderPreview: WorksheetPage
    borderImage?: number

    static all_alignments = [ImageAlignment.portrait, ImageAlignment.landscape]
    static all_properties = [ImageProperty.vector, ImageProperty.photo, ImageProperty.illustration]
    static all_colors = [ImageColor.color, ImageColor.black_white, ImageColor.coloring_picture]
    static all_backgrounds = [ImageBackground.withBackground, ImageBackground.withoutBackground]
    static all_licenses = [ImageLicense.free, ImageLicense.license]

    constructor(search: string,
                originatorId: number,
                collectionId: number,
                alignments: ImageAlignment[],
                properties: ImageProperty[],
                colors: ImageColor[],
                backgrounds: ImageBackground[],
                licenses: ImageLicense[],
                frameType: FrameType,
                borderRadius: number,
                borderTransparency: number,
                borderOptions: PageBorderPosition,
                borderStyle: BorderStyle,
                borderPreview: WorksheetPage,
                borderImage?: number) {
        super()

        this.search = search
        this.originatorId = originatorId
        this.collectionId = collectionId
        this.alignments = alignments
        this.properties = properties
        this.colors = colors
        this.backgrounds = backgrounds
        this.licenses = licenses

        this.pageFrame = true
        this.frameType = frameType
        this.borderRadius = borderRadius
        this.borderTransparency = borderTransparency
        this.borderOptions = borderOptions
        this.borderStyle = borderStyle
        this.borderPreview = borderPreview
        this.borderImage = borderImage
    }

    static initFilter() {
        return new FrameImageListFilter("", -1, -1,
            FrameImageListFilter.all_alignments, FrameImageListFilter.all_properties, FrameImageListFilter.all_colors,
            FrameImageListFilter.all_backgrounds, FrameImageListFilter.all_licenses, FrameType.GRAPHIC, 0, 0,
            PageBorderPosition.getDefaultPageBorder(), BorderStyle.getNonBorderStyle(), FrameImageListFilter.initPreview(), undefined)
    }
    static initPreview() {
        return new WorksheetPage(
            "preview",
            false,
            PageBorderPosition.getDefaultPageBorder(),
            PageBorderPosition.getNoPageBorder(),
            0)
    }
    static initPreviewWithoutPageBorder() {
        return new WorksheetPage(
            "preview",
            false,
            PageBorderPosition.getNoPageBorder(),
            PageBorderPosition.getNoPageBorder(),
            0)
    }
}
