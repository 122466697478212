import React from "react";
import {MainContext} from "../../_base/MainContext";
import {WDSheet} from "../WDSheet";
import {WorksheetPage} from "../../_model/WorksheetPage";
import {Worksheet} from "../../_model/Worksheet";
import {Coords} from "../../Framework/Coords";
import {WDToolbarButtonLineColor} from "../Toolbar/Button/General/WDToolbarButtonLineColor";
import {WDToolbarAction} from "../Toolbar/WDToolbarAction";
import {ImagePath} from "../../Framework/CategoryImage";
import {TooltipPosition, TooltipText} from "../../Components/Tooltips";
import translations from "../../Framework/translations.json";
import {WDToolbarButton} from "../Toolbar/Button/WDToolbarButton";
import {WDUtils} from "../Utils/WDUtils";
import {WorksheetItem} from "../../_model/WorksheetItem";
import {WDToolbarButtonSolutionShow} from "../Toolbar/Button/Math/WDToolbarButtonSolutionShow";
import _ from "lodash";
import {WDPresentationAction} from "./WDPresentationAction";
import {LineWidth} from "../Toolbar/Button/General/WDToolbarButtonColorPicker";
import {WDToolbarButtonEraser} from "../Toolbar/Button/General/WDToolbarButtonEraser";
import {FloatingHint} from "../../Components/Notification/FloatingHint";
import {NotificationData} from "../../Components/Notification/Hint";
import {NotificationStatus, RenderingMedia, SolutionForceMode} from "../../Framework/Enums";
import PlusButton from "../../Components/PlusButton/PlusButton";

interface IProps {
    worksheet: Worksheet
    elements: WorksheetItem[]
    contentWidth: number
    contentHeight: number

    onEndPresentationMode: () => void
    onScrollDocument: () => void
    onToolbarAction: (e: MouseEvent, action: WDPresentationAction, data?: any) => void
    onChangeZoom: (zoom: number) => void
}
interface IState {
    canvas?: HTMLCanvasElement
    canvasHeight: number
    showMenu: boolean
    showSolutionPages: boolean

    toolMode: ToolMode
    isUsingTool: boolean

    eraserWidth: LineWidth
    penWidth: LineWidth
    penColor: string

    activeId: number
    error?: string
}

enum ToolMode {
    None,
    Pen,
    Eraser,
    Move,
    Solution
}

export class WDPresentation extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    mouseCoords: Coords | undefined = undefined

    constructor(props: IProps) {
        super(props);

        // Needed to show solution pages instead of worksheetPages if all pages are declared as solution
        let hasWorksheetPages = this.getAmountOfWorksheetPages() > 0

        this.state = {
            canvasHeight: this.getCanvasHeight(!hasWorksheetPages),
            showMenu: true,
            showSolutionPages: !hasWorksheetPages,
            toolMode: ToolMode.None,
            isUsingTool: false,
            eraserWidth: new LineWidth(10, this.setEraserWidth, 30, 1),
            penWidth: new LineWidth(5, this.setPenWidth, 20, 1),
            penColor: "black",
            activeId: -1
        }
        window.addEventListener('keydown', this.onKeyDown, false)
        document.addEventListener('fullscreenchange', this.onFullScreenChange, false)
    }

    componentDidMount() {
        let canvas = document.getElementById("ws-designer-presentation-canvas") as HTMLCanvasElement
        let boundingRect = this.getBoundingRect()

        if(boundingRect) {
            canvas.width = boundingRect.width
            canvas.height = boundingRect.height
        }

        this.setState({canvas: canvas})
    }
    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown)
        document.removeEventListener('mousedown', this.onToggleShowSolution)
        document.removeEventListener('fullscreenchange', this.onFullScreenChange)
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    getBoundingRect = () => {
        let canvasArea = document.getElementById("ws-designer-presentation-content")
        return canvasArea?.getBoundingClientRect()
    }
    getAmountOfWorksheetPages = () => {
        if (this.props.worksheet.pages) {
            return this.props.worksheet.pages.filter(p => !p.solution).length
        }
        return 0
    }
    getCanvasHeight = (showSolutionPages: boolean) => {
        let canvasHeight = this.props.contentHeight
        if (this.props.worksheet.pages) {
            let amountOfShownPages = this.props.worksheet.pages.filter(p => p.solution === showSolutionPages).length
            canvasHeight = canvasHeight / this.props.worksheet.pages.length * amountOfShownPages
        }
        return canvasHeight
    }

    onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            e.preventDefault()
            e.stopPropagation()
            this.context.setZoom(1)
            this.props.onEndPresentationMode()
        }
    }
    onFullScreenChange = (e: Event) => {
        if (!document.fullscreenElement) {
            e.preventDefault()
            e.stopPropagation()
            this.context.setZoom(1)
            this.props.onEndPresentationMode()
        }
    }
    onTouchDownCanvas = (event: TouchEvent) => {
        event.preventDefault()

        let amountOfTouches = event.touches.length

        if(amountOfTouches > 1 || this.state.toolMode === ToolMode.Move) {
            this.mouseCoords = new Coords(event.touches[0].clientX, event.touches[0].clientY)
            this.setState({isUsingTool: true, activeId: -1})
        }
        else if((this.state.toolMode === ToolMode.Pen || this.state.toolMode === ToolMode.Eraser)
            && !this.state.isUsingTool && this.state.canvas) {

            let canvas = this.state.canvas
            let context = canvas?.getContext('2d')

            if(context !== undefined && context !== null) {
                context.lineCap = 'round'
                context.lineJoin = 'round'
                context.lineWidth = this.state.toolMode === ToolMode.Pen ? this.state.penWidth.lineWidth : this.state.eraserWidth.lineWidth
                context.strokeStyle = this.state.penColor

                this.draw(event.touches[0].clientX, event.touches[0].clientY, true)
                this.setState({isUsingTool: true, activeId: -1})
            }
        }
    }
    onMouseDownCanvas = (event: MouseEvent) => {
        event.preventDefault()

        if((this.state.toolMode === ToolMode.Pen || this.state.toolMode === ToolMode.Eraser)
            && !this.state.isUsingTool && this.state.canvas && event.buttons !== 4) {

            let canvas = this.state.canvas
            let context = canvas?.getContext('2d')

            if(context !== undefined && context !== null) {
                context.lineCap = 'round'
                context.lineJoin = 'round'
                context.lineWidth = this.state.toolMode === ToolMode.Pen ? this.state.penWidth.lineWidth : this.state.eraserWidth.lineWidth
                context.strokeStyle = this.state.penColor

                this.draw(Math.round(event.clientX), Math.round(event.clientY), true)
                this.setState({isUsingTool: true, activeId: -1})
            }
        }
        else if(this.state.toolMode === ToolMode.Move || event.buttons === 4) {
            this.mouseCoords = new Coords(event.clientX, event.clientY)
            this.setState({isUsingTool: true, activeId: -1})
        }
    }
    onTouchEndCanvas = (event: TouchEvent) => {
        if((this.state.toolMode === ToolMode.Pen || this.state.toolMode === ToolMode.Eraser) && this.state.isUsingTool) {
            this.draw(event.touches[0].clientX, event.touches[0].clientY, true)
            this.setState({isUsingTool: false})
        }
        else if(this.state.isUsingTool) {
            this.mouseCoords = undefined
            this.setState({isUsingTool: false})
        }
    }
    onMouseUpCanvas = (event: MouseEvent) => {
        event.preventDefault()

        if((this.state.toolMode === ToolMode.Pen || this.state.toolMode === ToolMode.Eraser) && this.state.isUsingTool && event.buttons !== 4) {
            this.draw(Math.round(event.clientX), Math.round(event.clientY), true)
            this.setState({isUsingTool: false})
        }
        else if(this.state.isUsingTool) {
            this.mouseCoords = undefined
            this.setState({isUsingTool: false})
        }
    }
    onTouchMoveCanvas = (event: TouchEvent) => {
        event.preventDefault()
        let amountOfTouches = event.touches.length
        console.log("onTouchMoveCanvas")

        if((amountOfTouches > 1 || this.state.toolMode === ToolMode.Move) && this.state.isUsingTool) {
            if(this.mouseCoords) {
                //this.mouseCoords = WDUtils.scrollToPosition(event.nativeEvent, this.mouseCoords, "ws-designer-presentation-document")
            }
        }
        else if((this.state.toolMode === ToolMode.Pen || this.state.toolMode === ToolMode.Eraser) && this.state.isUsingTool) {
            this.draw(Math.round(event.touches[0].clientX), Math.round(event.touches[0].clientY), false)
        }
    }
    onMouseMoveCanvas = (event: MouseEvent) => {
        if((this.state.toolMode === ToolMode.Pen || this.state.toolMode === ToolMode.Eraser) && this.state.isUsingTool && event.buttons !== 4) {
            this.draw(Math.round(event.clientX), Math.round(event.clientY), false)
        }
        else if ((this.state.toolMode === ToolMode.Move || event.buttons === 4) && this.state.isUsingTool) {
            if(this.mouseCoords) {
                this.mouseCoords = WDUtils.scrollToPosition(event, this.mouseCoords, "ws-designer-presentation-document")
            }
        }
    }

    draw = (posX: number, posY: number, startNew: boolean) => {
        if(!this.state.isUsingTool || (this.state.toolMode !== ToolMode.Pen && this.state.toolMode !== ToolMode.Eraser)) {
            return
        }

        let context = this.state.canvas?.getContext('2d')
        let drawWidth = this.state.toolMode === ToolMode.Pen ? this.state.penWidth : this.state.eraserWidth

        if(context !== undefined && context !== null) {
            if(this.state.toolMode === ToolMode.Pen) {
                context.globalCompositeOperation = "source-over";
            }
            else if(this.state.toolMode === ToolMode.Eraser) {
                context.globalCompositeOperation = "destination-out";
            }

            // get bounding rect of canvas to calculate zoom value
            let boundingRect = this.getBoundingRect()
            let boundingRectLeft = boundingRect !== undefined ? boundingRect.left : 0
            let boundingRectTop = boundingRect !== undefined ? boundingRect.top : 0

            if (startNew) {
                context.beginPath()

                // draw arc to draw anything if only clicked on one position
                context.arc(
                    (posX - boundingRectLeft) / this.context.getZoom(),
                    (posY - boundingRectTop) / this.context.getZoom(),
                    drawWidth.lineWidth / 2, 0, 2 * Math.PI)
                context.fillStyle = this.state.penColor;
                context.fill();

                context.beginPath()
            } else {
                context.lineTo(
                    (posX - boundingRectLeft) / this.context.getZoom(),
                    (posY - boundingRectTop) / this.context.getZoom())
            }
            context.stroke()
        }
    }

    onToggleMenu = () => {
        this.setState({showMenu: !this.state.showMenu})
    }
    onToggleButtonSubmenu = (id: number) => {
        this.setState({activeId: id})
    }
    setMode = (toolMode: ToolMode) => {
        if(toolMode === ToolMode.Eraser && this.state.toolMode !== ToolMode.Eraser) {
            this.setState({activeId: -1, toolMode: ToolMode.Eraser})
        }
        else if(toolMode === ToolMode.Move && this.state.toolMode !== ToolMode.Move) {
            this.setState({activeId: -1, toolMode: ToolMode.Move})
        }
        else if(toolMode === ToolMode.Solution && this.state.toolMode !== ToolMode.Solution) {
            document.addEventListener("mousedown", this.onToggleShowSolution)
            //document.addEventListener("touchstart", this.onToggleShowSolution)
            this.setState({activeId: -1, toolMode: ToolMode.Solution})
        }
        else {
            document.removeEventListener("mousedown", this.onToggleShowSolution)
            //document.removeEventListener("touchstart", this.onToggleShowSolution)
            this.setState({activeId: -1, toolMode: ToolMode.None})
        }
    }

    setPenColor = (action: WDToolbarAction, data: any) => {
        if(data["lineColor"] && data["lineColor"] !== undefined) {
            this.setState({activeId: -1, toolMode: ToolMode.Pen, penColor: "#" + data["lineColor"]})
        }
        else {
            this.setState({activeId: -1, toolMode: ToolMode.None})
        }
    }
    setPenWidth = (lineWidth: number) => {
        let lineStyle = this.state.penWidth
        lineStyle.lineWidth = lineWidth
        this.setState({penWidth: lineStyle})
    }
    setEraserWidth = (lineWidth: number) => {
        let lineStyle = this.state.eraserWidth
        lineStyle.lineWidth = lineWidth
        this.setState({eraserWidth: lineStyle})
    }
    setSolution = (action: WDToolbarAction, data: any) => {
        // Do nothing
    }

    onToggleShowSolution = (e: MouseEvent) => {
        if(this.state.toolMode === ToolMode.Solution) {
            // Check if clicked on item
            let worksheetItem = WDUtils.getWorksheetItemByCoords(new Coords(e.clientX, e.clientY))
            if (worksheetItem && worksheetItem.id) {
                this.onClickElementToggleShowSolution(e)
            }
            // If not - check if clicked on sheet
            else {
                let worksheetPage = WDUtils.getSheetByCoords(new Coords(e.clientX, e.clientY))

                if (worksheetPage && worksheetPage.id) {
                    this.onClickPageToggleShowSolution()
                }
            }
        }
    }
    onClickElementToggleShowSolution = (e: MouseEvent) => {
        this.props.onToolbarAction(e, WDPresentationAction.CHANGE_SOLUTION_SHOW, {"showSolution": true})
    }
    onClickPageToggleShowSolution = () => {
        let worksheetPagesWithSolution = this.props.worksheet.pages?.filter(p => p.solution === !this.state.showSolutionPages)

        if(worksheetPagesWithSolution !== undefined && worksheetPagesWithSolution.length > 0) {
            let newCanvasHeight = this.getCanvasHeight(!this.state.showSolutionPages)
            this.setState({showSolutionPages: !this.state.showSolutionPages, canvasHeight: newCanvasHeight})
        } else {
            let hasWorksheetPages = this.getAmountOfWorksheetPages() > 0
            this.setState({error: hasWorksheetPages ? this.context.translate(translations.error.no_solution_page) : this.context.translate(translations.error.no_worksheet_page) })
        }
    }

    onHideError = () => {
        this.setState({error: undefined})
    }

    renderSheets = () => {
        if(this.props.worksheet.pages) {
            return this.props.worksheet.pages
                .filter(p => !p.deleted)
                .filter(p => p.solution === this.state.showSolutionPages)
                .sort((a, b) => a.sort - b.sort)
                .map(i => {
                    return <WDSheet
                        key={WorksheetPage.getUniqueElementIdentifier(i)}
                        worksheetPage={i}
                        format={this.props.worksheet.format}
                        orientation={this.props.worksheet.orientation}
                        solution={i.solution}
                        isEditingAllowed={false}
                        showNonPrintableObjects={false}
                        renderingMedia={RenderingMedia.screen}
                        addElementToDesigner={() => {}}
                        addElementToGroup={() => {}}
                        onElementEdit={() => {}}
                        onElementResize={() => {}}
                        onElementSelect={() => {}}
                        onUpdateElement={() => {}}
                        onChangePageSettings={() => {}}
                        onChangePageBorder={() => {}}
                        onPageAdd={() => {}}
                        openSidebar={() => {}}
                        onContextMenu={() => {}}
                        onDropElement={() => {}}
                        deleteChildren={() => {}}
                        onElementConvert={() => {}}
                        onElementLoaded={() => {}}
                        onPropagateElementEvent={() => {}}
                        onResizeStateChanged={() => {}}
                        updateElementData={() => {}}
                        updateHistory={() => {}}
                        pushHistory={() => {}}
                        solutionForceMode={SolutionForceMode.Off}
                        inPresentationMode={true}
                        elements={WDUtils.getWorksheetItemsByPage(this.props.elements, WorksheetPage.getUniqueElementIdentifier(i))}
                    />
                })
        }
    }

    render() {
        let cursorClass = ""

        switch (this.state.toolMode) {
            case ToolMode.Eraser:
                cursorClass = "cursor-eraser"
                break

            case ToolMode.Move:
                cursorClass = "cursor-grab"
                break

            case ToolMode.Pen:
                cursorClass = "cursor-pen"
                break
        }

        // If error - get positioning with zoom
        let errorPositionPxTop = 50
        let errorPositionPxLeft = 22

        if (this.state.error) {
            let boundingRect = this.getBoundingRect()
            let boundingRectLeft = boundingRect ? boundingRect.left : 0
            let boundingRectTop = boundingRect ? boundingRect.top : 0

            //let scrollAmount = document.getElementsByClassName("ws-designer-presentation-document")[0]?.scrollTop
            errorPositionPxLeft = (errorPositionPxLeft - boundingRectLeft) / this.context.getZoom()
            errorPositionPxTop = (errorPositionPxTop - boundingRectTop) / this.context.getZoom()
        }

        return  <div className="ws-designer-presentation">

                {/* Menu for presentation tools */}
                {this.state.showMenu &&
                <div className="ws-designer-presentation-menu">
                    <div className="ws-designer-presentation-menu-section">
                        <img src={ImagePath.getMenuUrl() + "burger_plus.svg"}
                             alt={""}
                             className="ws-designer-presentation-menu-section-img"
                             onClick={this.onToggleMenu}
                        />
                    </div>

                    <div className="ws-designer-presentation-menu-section" style={{flexGrow: 1}}>
                        <WDToolbarButton
                            id={10}
                            arrow={false}
                            pressed={this.state.toolMode === ToolMode.Move}
                            enabled={true}
                            onToggleButton={() => this.setMode(ToolMode.Move)}
                            icon={ImagePath.getButtonUrl() + "move.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.text.presentation_mode.tool_move), this.context.translate(translations.tooltip.presentation_mode.tool_move))}
                            tooltipPosition={TooltipPosition.below}
                        />

                        <WDToolbarButtonLineColor id={1}
                                                  lineWidth={this.state.penWidth}
                                                  clickable={true}
                                                  open={this.state.activeId === 1}
                                                  icon={ImagePath.getButtonUrl() + "pen.svg"}
                                                  pressed={this.state.toolMode === ToolMode.Pen}
                                                  onToggleButton={this.onToggleButtonSubmenu}
                                                  onActionButton={this.setPenColor}
                                                  tooltip={new TooltipText(this.context.translate(translations.text.presentation_mode.tool_pen), this.context.translate(translations.tooltip.presentation_mode.tool_pen))}
                                                  tooltipPosition={TooltipPosition.below}
                        />

                        <WDToolbarButtonEraser id={20}
                                               clickable={true}
                                               pressed={this.state.toolMode === ToolMode.Eraser}
                                               open={this.state.activeId === 20}
                                               icon={ImagePath.getButtonUrl() + "erase.svg"}
                                               lineWidth={this.state.eraserWidth}
                                               tooltip={new TooltipText(this.context.translate(translations.text.presentation_mode.tool_eraser), this.context.translate(translations.tooltip.presentation_mode.tool_eraser))}
                                               tooltipPosition={TooltipPosition.below}
                                               onToggleButton={this.onToggleButtonSubmenu}
                                               onActionButton={() => this.setMode(ToolMode.Eraser)}/>

                        <WDToolbarButtonSolutionShow id={40}
                                                     clickable={true}
                                                     pressed={this.state.toolMode === ToolMode.Solution}
                                                     onToggleButton={() => this.setMode(ToolMode.Solution)}
                                                     onActionButton={this.setSolution}
                                                     tooltip={new TooltipText(this.context.translate(translations.text.presentation_mode.show_solution), this.context.translate(translations.tooltip.presentation_mode.show_solution))}
                                                     tooltipPosition={TooltipPosition.below}
                        />
                    </div>

                    <div className="ws-designer-presentation-menu-section" style={{alignSelf: "flex-end", marginRight: '10px'}}>
                        <WDToolbarButton
                            id={30}
                            arrow={false}
                            pressed={false}
                            enabled={true}
                            onToggleButton={() => {
                                this.context.setZoom(1)
                                this.props.onEndPresentationMode()
                            }}
                            icon={ImagePath.getButtonUrl() + "presentation_close.svg"}
                            tooltip={new TooltipText(this.context.translate(translations.text.presentation_mode.end_mode), this.context.translate(translations.tooltip.presentation_mode.end_mode))}
                            tooltipPosition={TooltipPosition.belowRight}
                        />
                    </div>
                </div>
                }
                {!this.state.showMenu &&
                    <div className="ws-designer-presentation-menu-small">
                        <div className="ws-designer-presentation-menu-section">
                            <img src={ImagePath.getMenuUrl() + "burger_minus.svg"}
                                 alt={""}
                                 className="ws-designer-presentation-menu-section-img"
                                 onClick={this.onToggleMenu}
                            />
                        </div>
                    </div>
                }

                {/* Scrollable container for sheet and elements*/}
                <div id="ws-designer-presentation-document" className={"ws-designer-presentation-document " + cursorClass} onScroll={this.props.onScrollDocument}>

                    {/* Content frame with all sheets */}
                    <div id="ws-designer-presentation-content" className="ws-designer-presentation-content"
                         style={{minWidth: this.props.contentWidth, height: this.state.canvasHeight}}>
                        {this.renderSheets()}

                        {this.state.error &&
                            <FloatingHint id={"error-no-solution-page"}
                                          style={{width: "500px", left: errorPositionPxLeft + "px", top: errorPositionPxTop + "px"}}
                                          onHide={this.onHideError}
                                          notificationData={new NotificationData(NotificationStatus.info, this.state.error)}/>
                        }

                        <canvas id={"ws-designer-presentation-canvas"}
                                className={"ws-designer-presentation-canvas"}
                                onMouseDown={event => this.onMouseDownCanvas(event.nativeEvent)}
                                onTouchStart={event => this.onTouchDownCanvas(event.nativeEvent)}
                                onMouseUp={event => this.onMouseUpCanvas(event.nativeEvent)}
                                onTouchEnd={event => this.onTouchEndCanvas(event.nativeEvent)}
                                onTouchCancel={event => this.onTouchEndCanvas(event.nativeEvent)}
                                onMouseMove={event => this.onMouseMoveCanvas(event.nativeEvent)}
                                onTouchMove={event => this.onTouchMoveCanvas(event.nativeEvent)}
                        />
                    </div>
                </div>

                <PlusButton
                    positionRight={"5%"}
                    zoom={this.context.getZoom()}
                    onChangeZoom={this.props.onChangeZoom}
                />
        </div>
    }
}

