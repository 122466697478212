import React from "react";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../WDElementBase";
import {ResizeInfo, WDElementContainer} from "../WDElementContainer";
import Const from "../../../Framework/Const";
import {MainContext} from "../../../_base/MainContext";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {NameValueToEnumKey} from "../../../Framework/Enums";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../../Framework/CategoryImage";

export enum WDShapeType3d {
    Prisma_3sided = "prisma_3sided",
    Prisma_3sided_lines = "prisma_3sided_lines",
    Cone = "cone",
    Cone_lines = "cone_lines",
    Sphere = "sphere",
    Sphere_lines = "sphere_lines",
    Pyramid = "pyramid",
    Pyramid_lines = "pyramid_lines",
    Cuboid = "cuboid",
    Cuboid_lines = "cuboid_lines",
    Cube = "cube",
    Cube_lines = "cube_lines",
    Cuboid_high = "cuboid_high",
    Cuboid_high_lines = "cuboid_high_lines",
    Prisma_6sided = "prisma_6sided",
    Prisma_6sided_lines = "prisma_6sided_lines",
    Cylinder = "cylinder",
    Cylinder_lines = "cylinder_lines"
}

export class WDShapeData3d extends WDElementBaseData {
    type: WDShapeType3d

    constructor(type: WDShapeType3d) {
        super()
        this.type = type
    }

    static serialize = (data: WDShapeData3d): string => {
        return JSON.stringify(data, (key, value) => {
            if (key === "url" || key === "width" || key === "height") return undefined;
            else return value;
        })
    }
}

interface IProps extends WDElementBaseProps {
    data: WDShapeData3d
    isReadOnly: boolean
}

interface IState extends WDElementBaseState {
}

export class WDShape3d extends WDElementBase<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        this.state = {
            isEdited: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            elementRef: React.createRef()
        }
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    getMinWidth = () => {
        return 80
    }
    getMinHeight = () => {
        return 80
    }

    static getDefaultWidth = (configData: string) => {
        let dataObject = JSON.parse(configData)

        if (dataObject.type) {
            let typ = WDShapeType3d[NameValueToEnumKey(dataObject.type, WDShapeType3d)]
            switch (typ) {
                case WDShapeType3d.Cuboid:
                    return 250
                case WDShapeType3d.Cuboid_lines:
                    return 250
                case WDShapeType3d.Cuboid_high:
                    return 100
                case WDShapeType3d.Cuboid_high_lines:
                    return 100
                default:
                    return 150
            }
        }
        return 150
    }
    static getDefaultHeight = () => {
        return 150
    }
    static mapImageKeyToShapeType = (imageKey: string) : WDShapeType3d => {
        let type = imageKey.replace("FORM_", "").toLowerCase()
        return WDShapeType3d[NameValueToEnumKey(type, WDShapeType3d)]
    }
    static mapShapeTypeToImage = (type: WDShapeType3d) : string => {
        let imageKey = "FORM_" + NameValueToEnumKey(type, WDShapeType3d).toUpperCase()
        return CategoryImageValue.getImageByKey([ImageCategory.FORMS_3D], imageKey)
    }

    getImageSrcWithoutThumb = () => {
        let thumbImage = WDShape3d.mapShapeTypeToImage(this.props.data.type)
        return thumbImage.substring(0, thumbImage.lastIndexOf("-thumb.svg"))
    }

    doAction = (action: WDToolbarAction, data?: string) => {
        let newData = {...this.props.data}

        let update = new WorksheetItemUpdate(this.props.id, {})
        switch (action) {
            case WDToolbarAction.CHANGE_GRAPHIC:
                if(data && data["image"]) {
                    let typeString = WDShape3d.mapImageKeyToShapeType(data["image"])
                    newData.type = WDShapeType3d[NameValueToEnumKey(typeString, WDShapeType3d)]
                }
                break

            default:
                break
        }

        update.value.content = this.serializeElementData(newData)
        return update
    }

    onResizeElement = (proportional: boolean, x: number, y: number) => {
        this.props.onElementResize?.(true, x, y)
    }

    /**
     * Overridden methods
     */
    hasNameConfigInstancesEnabled = (): boolean => {
        return false
    }
    serializeElementData = (data: WDElementBaseData): string => {
        return WDShapeData3d.serialize(data as WDShapeData3d)
    }

    render() {
        let resizeInfo: ResizeInfo =
            new ResizeInfo(!this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly, !this.props.isReadOnly,
                false, false, false, false,
                this.getMinWidth(), Const.MaxElementSize, this.getMinHeight(), Const.MaxElementSize)

        // if element is marked as deleted, do not render
        if(this.props.element.deleted) {
            return <></>
        }

        let defaultSrc = this.getImageSrcWithoutThumb()
        let shapeColor = defaultSrc.substring(0, defaultSrc.length - 1) + "-f.svg"
        let shapeBorder = defaultSrc + "-r.svg"

        let borderColorClass = " svg-color-" + this.props.element.borderColor.replace("#", "")
        let fillColorClass = " svg-color-" + this.props.element.fillColor.replace("#", "")

        return <WDElementContainer
            id={this.props.id}
            element={this.props.element}
            hasResizeOnCreate={false}
            resizeInfo={resizeInfo}
            renderWrapper={true}
            renderBackgroundColor={false}
            renderBorder={false}
            onUnlockElement={this.unlockElement}
            onResizeStateChanged={this.props.onResizeStateChanged}
            onResizeElement={this.onResizeElement}
            isEditModeAllowed={() => false}
            isReadOnly={this.props.isReadOnly}
            onContextMenu={this.props.onContextMenu}
            ref={this.state.elementRef}
        >

            <div className={"ws-designer-shape print"}>
                {this.props.element.fillColor !== 'transparent' &&
                <img className={"ws-designer-shape-image" + fillColorClass} alt={""}
                     src={process.env.PUBLIC_URL + ImagePath.getFormsUrl() + shapeColor}/>
                }
                <img className={"ws-designer-shape-image" + borderColorClass} alt={""}
                     src={process.env.PUBLIC_URL + ImagePath.getFormsUrl() + shapeBorder}/>
            </div>

        </WDElementContainer>
    }
}
