export default class Const {
    static ApplicationName : string = "vicido";

    static CreateNewDataUrl: string = "new"

    static WorksheetDefaultPageWidth: number = 794;
    static WorksheetDefaultPageHeight: number = 1123;
    static MinElementSize: number = 30;
    static MaxElementSize: number = 1587;

    static WorksheetPageBorderPX: number = 10;
    static WorksheetDesignBorderMM: number = 10;

    // DataTransfer values
    static DATA_TRANSFER_CONFIG_DATA: string = "configData"
    static DATA_TRANSFER_WS_ITEM_ID: string = "worksheetItemTypeId"
    static DATA_TRANSFER_IMAGE_COLOR: string = "imageColor"

    static COLOR_APP_BACKGROUND = "#f6f7fb"
    static COLOR_PRIMARY = "#6d80bf"
    static COLOR_PRIMARY_LIGHT = "#8897BB"
    static COLOR_DARK_GREY = "#1e1f28"
    static COLOR_RED = "#d8394c"

    static FONT_VICIDO_SANS = "VicidoSans"
    static FONT_SIZE = 18

    static EMPTY_IMAGE = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII="

    static SUPPORT_EMAIL = "support@vicido.at"
    static TELEPHONE = 432639249660
    static TELEPHONE_STRING = "+43 2639 249660"

    static DESIGNER_MAX_PAGE_COUNT = 20

    static ELEMENT_PADDING = 20
}
