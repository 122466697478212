import {WorksheetPage} from "../../_model/WorksheetPage";

export class WDPageHistoryItemElement {
    key: string
    sort: number
    deleted: boolean
    solution: boolean
    borderLeft: number
    borderTop: number
    borderRight: number
    borderBottom: number
    borderImage?: number
    borderColor?: string
    borderStyle?: string
    borderWeight?: number
    borderRadius?: number
    borderTransparency?: number
    trimBorderLeft: number
    trimBorderTop: number
    trimBorderRight: number
    trimBorderBottom: number
    linkBorders: boolean

    constructor(item: WorksheetPage) {
        this.key = WorksheetPage.getUniqueElementIdentifier(item)
        this.deleted = item.deleted
        this.sort = item.sort
        this.solution = item.solution
        this.borderLeft = item.borderLeft
        this.borderTop = item.borderTop
        this.borderRight = item.borderRight
        this.borderBottom = item.borderBottom
        this.borderImage = item.borderImage
        this.borderStyle = item.borderStyle
        this.borderColor = item.borderColor
        this.borderWeight = item.borderWeight
        this.borderRadius = item.borderRadius
        this.borderTransparency = item.borderTransparency
        this.trimBorderLeft = item.trimBorderLeft
        this.trimBorderTop = item.trimBorderTop
        this.trimBorderRight = item.trimBorderRight
        this.trimBorderBottom = item.trimBorderBottom
        this.linkBorders = item.linkBorders
    }

    restore(page: WorksheetPage) {
        page.deleted = this.deleted
        page.sort = this.sort
        page.solution = this.solution
        page.borderLeft = this.borderLeft
        page.borderTop = this.borderTop
        page.borderRight = this.borderRight
        page.borderBottom = this.borderBottom
        page.borderImage = this.borderImage
        page.borderStyle = this.borderStyle
        page.borderColor = this.borderColor
        page.borderWeight = this.borderWeight
        page.borderRadius = this.borderRadius
        page.borderTransparency = this.borderTransparency
        page.trimBorderLeft = this.trimBorderLeft
        page.trimBorderTop = this.trimBorderTop
        page.trimBorderRight = this.trimBorderRight
        page.trimBorderBottom = this.trimBorderBottom
        page.linkBorders = this.linkBorders
    }
}
