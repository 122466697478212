import React, {FormEvent} from "react";
import '../assets/css/layout.min.css';
import {MainContext} from "../_base/MainContext";
import {MenuInfo, Modal} from "../Components/Modal";
import translations from "../Framework/translations.json";
import {Worksheet} from "../_model/Worksheet";
import SelectBox from "../Components/Controls/SelectBox";
import LessonSubject from "../_model/LessonSubject";
import {GetAllLanguages, GetLessonSubject, GetSchoolLevel} from "../_endpoint/MetadataEndpoint";
import Language from "../_model/Language";
import SchoolLevel from "../_model/SchoolLevel";
import Entity from "../_model/Entity";
import {
    AdminObjectType, ColumnRenderOption, Difficulty,
    EnumToEntityArray,
    EnumValuesToEntityArray,
    EnumValueToValueNumber,
    WSMarketplaceStatus,
    WSPageFormat,
    WSStatus
} from "../Framework/Enums";
import {ImagePath} from "../Framework/CategoryImage";
import {GetWorksheetLogsById, GetWorksheetRatings, GetWorksheetThumbnail} from "../_endpoint/WorksheetEndpoint";
import {PageBorderPosition, WDPageBorder} from "../Components/PageBorder/WDPageBorder";
import {WDToolbarButton} from "./Toolbar/Button/WDToolbarButton";
import {TreeView} from "../Components/Controls/TreeView";
import {TooltipText} from "../Components/Tooltips";
import {WSContextType} from "./Elements/WSContext";
import {ButtonInfo} from "../Components/Controls/ButtonList";
import TextBox from "../Components/Controls/TextBox";
import CheckBox from "../Components/Controls/CheckBox";
import Tag from "../_model/Tag";
import {WorksheetLogListItem} from "../Admin/Marketplace/WorksheetLogListItem";
import List, {ColumnDefinition, ListItem} from "../Components/List/List";
import {ListEntry} from "../Components/List/ListEntry";
import {WorksheetRating} from "../_model/WorksheetRating";
import Rating from "../Components/Controls/Rating";
import Auth from "../Framework/Auth";
import {RouteComponentProps} from "react-router-dom";
import RatingList from "../Components/Controls/RatingList";

interface IProps extends RouteComponentProps {
    worksheet?: Worksheet
    onClose: () => void
    onSave: (settings: WorksheetSettings) => void
}

interface IState {
    settings: WorksheetSettings
    menuSettings: MenuInfo
    openSection: string
    thumbnail?: string
    tabHeight: number
    logs?: WorksheetLogListItem[]
    ratings?: WorksheetRating[]

    subject: LessonSubject[]
    language: Language[]
    schoolLevel: SchoolLevel[]
    status: Entity[]
}

export class WorksheetSettings {
    worksheetName?: string
    description?: string
    subject?: LessonSubject
    language?: Language
    difficulty?: Difficulty
    schoolLevel?: SchoolLevel[]
    tags?: Tag[]
    status?: WSStatus
    pageBorder: PageBorderPosition
    trimBorder: PageBorderPosition
    format: WSPageFormat
    orientation: boolean
    blackWhite: boolean
    isEditingAllowed: boolean

    marketplaceIsEditingAllowed: boolean
    marketplaceStatus?: WSMarketplaceStatus
    marketplaceShowName: boolean
    marketplaceShowEmail: boolean
    marketplaceTargetSchool: boolean
    marketplaceTargetPupils: boolean
    marketplaceTargetTeacher: boolean
    marketplaceTargetParents: boolean
    marketplaceKeywords?: string
    marketplaceAdvertisement: boolean

    constructor(worksheetName?: string, isEditingAllowed?: boolean, description?: string, subject?: LessonSubject, language?: Language,
                difficulty?: Difficulty, schoolLevel?: SchoolLevel[], tags?: Tag[],
                status?: WSStatus, format?: WSPageFormat, orientation?: boolean, pageBorder?: PageBorderPosition, trimBorder?: PageBorderPosition,
                blackWhite?: boolean, marketplaceStatus?: WSMarketplaceStatus, marketplaceIsEditingAllowed?: boolean, marketplaceShowName?: boolean,
                marketplaceShowEmail?: boolean, marketplaceTargetSchool?: boolean, marketplaceTargetPupils?: boolean, marketplaceTargetTeacher?: boolean,
                marketplaceTargetParents?: boolean, marketplaceAdvertisement?: boolean, marketplaceKeywords?: string) {

        this.worksheetName = worksheetName
        this.description = description
        this.subject = subject
        this.language = language
        this.difficulty = difficulty
        this.schoolLevel = schoolLevel
        this.tags = tags
        this.status = status
        this.format = format || WSPageFormat.A4
        this.orientation = orientation === undefined ? true : orientation
        this.pageBorder = pageBorder || PageBorderPosition.getDefaultPageBorder()
        this.trimBorder = trimBorder || new PageBorderPosition(0, 0, 0, 0, true)
        this.blackWhite = blackWhite || false
        this.isEditingAllowed = isEditingAllowed || false

        this.marketplaceStatus = marketplaceStatus
        this.marketplaceIsEditingAllowed = marketplaceIsEditingAllowed || false
        this.marketplaceShowName = marketplaceShowName || false
        this.marketplaceShowEmail = marketplaceShowEmail || false
        this.marketplaceTargetSchool = marketplaceTargetSchool || false
        this.marketplaceTargetPupils = marketplaceTargetPupils || false
        this.marketplaceTargetTeacher = marketplaceTargetTeacher || false
        this.marketplaceTargetParents = marketplaceTargetParents || false
        this.marketplaceKeywords = marketplaceKeywords
        this.marketplaceAdvertisement = marketplaceAdvertisement || false
    }

    static mapWorksheetToSettings(worksheet: Worksheet): WorksheetSettings {
        let border: PageBorderPosition | undefined = undefined, trimBorder: PageBorderPosition | undefined = undefined

        if (worksheet.pages && worksheet.pages.length > 0) {
            border = new PageBorderPosition(worksheet.pages[0].borderLeft, worksheet.pages[0].borderTop, worksheet.pages[0].borderRight,
                worksheet.pages[0].borderBottom, worksheet.pages[0].linkBorders)
            trimBorder = new PageBorderPosition(worksheet.pages[0].trimBorderLeft, worksheet.pages[0].trimBorderTop, worksheet.pages[0].trimBorderRight,
                worksheet.pages[0].trimBorderBottom, true)
        }

        return new WorksheetSettings(
            worksheet.name,
            worksheet.editingAllowed,
            worksheet.description,
            worksheet.subject,
            worksheet.language,
            worksheet.difficulty,
            worksheet.schoolLevels,
            worksheet.tags,
            worksheet.status,
            worksheet.format,
            worksheet.orientation,
            border,
            trimBorder,
            worksheet.blackWhite,
            worksheet.marketplaceStatus,
            worksheet.marketplaceIsEditingAllowed,
            worksheet.marketplaceShowName,
            worksheet.marketplaceShowEmail,
            worksheet.marketplaceTargetSchool,
            worksheet.marketplaceTargetPupils,
            worksheet.marketplaceTargetTeacher,
            worksheet.marketplaceTargetParents,
            worksheet.marketplaceAdvertisement,
            worksheet.marketplaceKeywords
        )
    }

    static mapSettingsToWorksheet(settings: WorksheetSettings, context: WSContextType, worksheet?: Worksheet): Worksheet | undefined {
        let mappedWorksheet = worksheet

        if (mappedWorksheet && settings.worksheetName) {
            mappedWorksheet.name = settings.worksheetName
            mappedWorksheet.context = context
        } else if (!mappedWorksheet && settings.worksheetName) {
            mappedWorksheet = new Worksheet(
                1,
                settings.worksheetName,
                context,
                settings.format,
                settings.isEditingAllowed,
                settings.orientation
            )
        }

        if (mappedWorksheet) {
            mappedWorksheet.description = settings.description
            mappedWorksheet.subject = settings.subject
            mappedWorksheet.language = settings.language
            mappedWorksheet.difficulty = settings.difficulty
            mappedWorksheet.schoolLevels = settings.schoolLevel
            mappedWorksheet.tags = settings.tags
            mappedWorksheet.status = settings.status || WSStatus.active
            mappedWorksheet.blackWhite = settings.blackWhite
            mappedWorksheet.format = settings.format
            mappedWorksheet.orientation = settings.orientation
            mappedWorksheet.marketplaceStatus = settings.marketplaceStatus
            mappedWorksheet.marketplaceIsEditingAllowed = settings.marketplaceIsEditingAllowed
            mappedWorksheet.marketplaceShowName = settings.marketplaceShowName
            mappedWorksheet.marketplaceShowEmail = settings.marketplaceShowEmail
            mappedWorksheet.marketplaceTargetSchool = settings.marketplaceTargetSchool
            mappedWorksheet.marketplaceTargetPupils = settings.marketplaceTargetPupils
            mappedWorksheet.marketplaceTargetTeacher = settings.marketplaceTargetTeacher
            mappedWorksheet.marketplaceTargetParents = settings.marketplaceTargetParents
            mappedWorksheet.marketplaceKeywords = settings.marketplaceKeywords
            mappedWorksheet.marketplaceAdvertisement = settings.marketplaceAdvertisement
        }

        return mappedWorksheet
    }
}

export class WDSettings extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.status), "marketplaceStatus", 60, false, false),
        new ColumnDefinition(this.context.translate(translations.fields.date), "createdOn", 40, false, false, ColumnRenderOption.DateTime),
    ]

    constructor(props: IProps, state: IState) {
        super(props, state);

        let names = [
            {id: "menu-setting-general", value: this.context.translate(translations.text.worksheet_settings.general)},
            {id: "menu-setting-format", value: this.context.translate(translations.text.worksheet_settings.format)}
        ]

        // Display marketplace tab only for original worksheets
        if (this.isMPOriginal()) {
            names.push({id: "menu-setting-marketplace", value: this.context.translate(translations.text.worksheet_settings.market)})
        }

        this.state = ({
            settings: this.props.worksheet ? WorksheetSettings.mapWorksheetToSettings(this.props.worksheet) : new WorksheetSettings(""),
            openSection: "menu-setting-general",
            subject: [],
            language: [],
            schoolLevel: [],
            status: [],
            tabHeight: this.getTabHeight(),
            menuSettings: new MenuInfo(
                names,
                "menu-setting-general",
                this.onChangeSettingSection
            )
        })
    }

    componentDidMount() {
        GetLessonSubject().then((result) => {
            this.setState({subject: result})
        })
        GetAllLanguages().then((result) => {
            this.setState({language: result})
        })
        GetSchoolLevel().then((result) => {
            this.setState({schoolLevel: result})
        })

        if (this.props.worksheet && this.props.worksheet.id) {

            // Load Thumbnail
            GetWorksheetThumbnail(this.props.worksheet.id).then((result) => {
                    this.setState({thumbnail: result.thumbnail})
                },
                (_) => {
                    // Ignore error when no thumbnail is available default thumbnail is shown
                })

            if (this.isMPOriginal()) {
                // Load worksheet logs
                GetWorksheetLogsById(this.props.worksheet.id).then(result => {
                    this.setState({logs: WorksheetLogListItem.convertWorksheetLogArray(result, this.context.translate)})
                })

                // Load worksheet ratings
                GetWorksheetRatings(this.props.worksheet.id).then(result => {
                    this.setState({ratings: result})
                })
            }
        }

        window.addEventListener("resize", this.onResizeWindow)

        this.setState({status: EnumValuesToEntityArray(WSStatus, Object.values(WSStatus), translations.enum.ws_status, this.context.translate)})
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResizeWindow)
    }

    onFormSubmit = (event: FormEvent) => {
        event.preventDefault();

        this.props.onSave(this.state.settings)
    }
    onFormCancel = () => {
        this.props.onClose()
    }

    onChangeSettingSection = (id: string) => {
        let newModalSettings = this.state.menuSettings
        newModalSettings.selectedId = id
        this.setState({openSection: id, menuSettings: newModalSettings})
    }
    onChangeString = (value: string, fieldName: string) => {
        let newSettings = this.state.settings
        newSettings[fieldName] = value
        this.setState({settings: newSettings})
    }
    onChangeSelection = (field: string, newValue: number) => {
        let newSettings = this.state.settings
        newSettings[field] = this.state[field].find(value => value.id === newValue)
        this.setState({settings: newSettings})
    }
    onChangeBoolean = (field: string, isChangeAllowed: boolean) => {
        if (isChangeAllowed) {
            let newSettings = this.state.settings
            newSettings[field] = !newSettings[field]
            this.setState({settings: newSettings})
        }
    }
    onChangeSchoolLevel = (values: SchoolLevel[]) => {
        let newSettings = this.state.settings
        newSettings.schoolLevel = values
        this.setState({settings: newSettings})
    }
    onChangeFormat = (value: number) => {
        let format = Object.values(WSPageFormat).find((e, i) => i === value)
        if (format) {
            let newSettings = this.state.settings
            newSettings.format = format
            this.setState({settings: newSettings})
        }
    }
    onChangeDifficulty = (value: number) => {
        let difficulty = Object.values(Difficulty).find((e, i) => i === value)
        if (difficulty) {
            let newSettings = this.state.settings
            newSettings.difficulty = difficulty === Difficulty.none ? undefined : difficulty
            this.setState({settings: newSettings})
        }
    }

    onChangePageBorder = (border: PageBorderPosition) => {
        let newSettings = this.state.settings
        newSettings.pageBorder = border
        this.setState({settings: newSettings})
    }
    onChangeTrimBorder = (border: PageBorderPosition) => {
        let newSettings = this.state.settings
        newSettings.trimBorder = border
        this.setState({settings: newSettings})
    }
    changePageOrientation = (orientation: boolean) => {
        let newSettings = this.state.settings
        newSettings.orientation = orientation
        this.setState({settings: newSettings})
    }

    getTabHeight = () => {
        return window.innerHeight * 0.7 - 100
    }
    onResizeWindow = () => {
        this.setState({tabHeight: this.getTabHeight()})
    }

    isMPOriginal = () => {
        return (this.props.worksheet?.marketplaceStatus === WSMarketplaceStatus.published
                || this.props.worksheet?.marketplaceStatus === WSMarketplaceStatus.approval
                || this.props.worksheet?.marketplaceStatus === WSMarketplaceStatus.updated) &&
            this.props.worksheet?.ownerId !== undefined &&
            this.props.worksheet?.sourceWorksheetId === null
    }
    isEditingAllowed = () => {
        return this.props.worksheet === undefined
            || (this.props.worksheet.editingAllowed && this.props.worksheet.marketplaceStatus !== WSMarketplaceStatus.approval)
            || (Auth.isAdmin() && Auth.getUserId() !== this.props.worksheet.ownerId?.id)
    }
    isMPTabEditingAllowed = () => {
        return this.props.worksheet === undefined
            || (Auth.isAdmin() && Auth.getUserId() !== this.props.worksheet.ownerId?.id)
    }

    render() {
        let tabHeight = this.getTabHeight()

        let logs = this.state.logs ? this.state.logs
            .map(item => new ListItem(item, React.createRef<ListEntry>())) : []

        return <Modal id={"settings"}
                      onFormSubmit={this.onFormSubmit}
                      onFormCancel={this.onFormCancel}
                      title={this.context.translate(translations.text.worksheet_settings.worksheet_settings)}
                      buttons={[
                          new ButtonInfo("btnCancelSettings", "button button-cancel", "button", false, false, this.context.translate(translations.command.cancel), this.onFormCancel, {}),
                          new ButtonInfo("btnSaveSettings", "button button-save", "submit", true, !this.isEditingAllowed(), this.context.translate(translations.command.save), undefined, {marginLeft: "10px"})
                      ]}
                      menu={this.state.menuSettings}
                      dialogStyle={{width: "70%", height: "70%"}}
                      contentAlignment={"flex-start"}
        >
            {/* Tab: General */}
            {this.state.openSection === "menu-setting-general" &&
                <div className={"modal-settings-tab"} style={{height: tabHeight}}>
                    <div className="form-group" style={{marginTop: 10}}>
                        <div className={"form-row"} style={{marginBottom: 20}}>
                            <TextBox id={"worksheetName"}
                                     label={this.context.translate(translations.text.worksheet_settings.title)}
                                     labelClass={"label-primary"}
                                     value={this.state.settings.worksheetName || ""}
                                     width={305}
                                     required={true}
                                     readonly={!this.isEditingAllowed()}
                                     multiline={false}
                                     onChange={(value) => this.onChangeString(value, "worksheetName")}
                            />
                        </div>

                        <div className={"form-row"} style={{marginBottom: 20}}>
                            <TextBox id={"description"}
                                     label={this.context.translate(translations.text.worksheet_settings.description)}
                                     labelClass={"label-primary"}
                                     value={this.state.settings.description || ""}
                                     required={false}
                                     readonly={!this.isEditingAllowed()}
                                     multiline={true}
                                     style={{width: "100%"}}
                                     onChange={(value) => this.onChangeString(value, "description")}
                            />
                        </div>

                        <div className={"form-row"} style={{marginBottom: 20}}>
                            <SelectBox id={"selSubject"}
                                       label={this.context.translate(translations.text.worksheet_settings.subject) + ":"}
                                       labelClass={"label-primary"}
                                       labelWidth={120}
                                       width={305}
                                       data={this.state.subject}
                                       onChange={(id) => this.onChangeSelection("subject", id)}
                                       value={this.state.settings?.subject?.id}
                                       required={false}
                                       readonly={!this.isEditingAllowed()}
                                       positioningRow={false}
                            />
                        </div>

                        <div className={"form-row"} style={{marginBottom: 20}}>
                            <SelectBox id={"selLanguage"}
                                       label={this.context.translate(translations.text.worksheet_settings.language) + ":"}
                                       labelClass={"label-primary"}
                                       labelWidth={120}
                                       width={305}
                                       data={this.state.language}
                                       onChange={(id) => this.onChangeSelection("language", id)}
                                       value={this.state.settings?.language?.id}
                                       required={false}
                                       readonly={!this.isEditingAllowed()}
                                       positioningRow={false}
                            />
                        </div>

                        <div className={"form-row"} style={{marginBottom: 20}}>
                            <SelectBox id={"selDifficulty"}
                                       label={this.context.translate(translations.fields.difficulty) + ":"}
                                       labelClass={"label-primary"}
                                       labelWidth={120}
                                       width={305}
                                       data={EnumToEntityArray(Difficulty, translations.enum.difficulty, this.context.translate)}
                                       onChange={(id) => this.onChangeDifficulty(id)}
                                       value={EnumValueToValueNumber(Difficulty, this.state.settings.difficulty)}
                                       required={false}
                                       readonly={!this.isEditingAllowed()}
                                       positioningRow={false}
                            />
                        </div>
                    </div>
                    <div className="form-group" style={{marginTop: 10, marginRight: 10}}>
                        {this.state.schoolLevel &&
                            <div className={"form-row"} style={{marginBottom: 20}}>
                                <TreeView
                                    id={"lstSubSchoolLevel"}
                                    data={this.state.schoolLevel}
                                    selectedData={this.state.settings?.schoolLevel || []}
                                    required={false}
                                    readonly={!this.isEditingAllowed()}
                                    parent={"parent"}
                                    width={250}
                                    onChange={this.onChangeSchoolLevel}
                                />
                            </div>
                        }
                    </div>

                    <div className="form-group" style={{marginTop: 10, height: "90%", paddingRight: 10}}>
                        <img src={this.state.thumbnail
                            ? ("data:image/jpeg;base64," + this.state.thumbnail)
                            : process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"}
                             alt={"thumbnail"} className={"ws-designer-settings-thumbnail"}/>
                    </div>
                </div>
            }

            {/* Tab: Format */}
            {this.state.openSection === "menu-setting-format" &&
                <div className={"modal-settings-tab"} style={{height: tabHeight}}>

                    <div className="form-group" style={{marginTop: 10, width: 450}}>
                        <div className={"ws-designer-settings-label-big"} style={{paddingBottom: 10}}>
                            {this.context.translate(translations.text.worksheet_settings.format)}
                        </div>

                        <SelectBox id={"selSize"}
                                   label={this.context.translate(translations.text.worksheet_settings.size) + ":"}
                                   labelClass={"ws-designer-settings-label"}
                                   labelWidth={120}
                                   width={90}
                                   data={EnumToEntityArray(WSPageFormat, translations.enum.ws_size, this.context.translate)}
                                   onChange={(id) => this.onChangeFormat(id)}
                                   value={EnumValueToValueNumber(WSPageFormat, this.state.settings.format)}
                                   required={false}
                                   readonly={!this.isEditingAllowed()}
                                   positioningRow={true}
                        />

                        <div className={"form-row"}>
                            <label
                                className={"ws-designer-settings-label"}>{this.context.translate(translations.text.worksheet_settings.page_orientation) + ":"}</label>

                            <WDToolbarButton id={1} arrow={false}
                                             icon={ImagePath.getPageUrl() + "vertical.svg"}
                                             tooltip={new TooltipText(this.context.translate(translations.toolbar.portrait))}
                                             pressed={this.state.settings.orientation}
                                             enabled={this.isEditingAllowed()}
                                             onToggleButton={() => this.changePageOrientation(true)}/>

                            <WDToolbarButton id={2} arrow={false}
                                             icon={ImagePath.getPageUrl() + "horizontal.svg"}
                                             tooltip={new TooltipText(this.context.translate(translations.toolbar.landscape))}
                                             pressed={!this.state.settings.orientation}
                                             enabled={this.isEditingAllowed()}
                                             onToggleButton={() => this.changePageOrientation(false)}/>
                        </div>

                        <div className={"ws-designer-settings-label-big"} style={{paddingTop: 10, paddingBottom: 10}}>
                            {this.context.translate(translations.text.worksheet_settings.page_border)}
                        </div>
                        <WDPageBorder border={this.state.settings.pageBorder}
                                      readonly={!this.isEditingAllowed()}
                                      smallRendering={false}
                                      linkReadOnly={!this.isEditingAllowed()}
                                      onChangeBorder={this.onChangePageBorder}/>

                        {/*<div className={"ws-designer-settings-label-big"} style={{paddingTop: 25, paddingBottom: 10}}>
                            {this.context.translate(translations.text.worksheet_settings.trim_border)}
                        </div>
                        <WDPageBorder border={this.state.settings.trimBorder}
                                      readonly={!this.isEditingAllowed()}
                                      linkReadOnly={!this.isEditingAllowed()}
                                      onChangeBorder={this.onChangeTrimBorder}/>*/}
                    </div>

                    <div className="form-group" style={{marginTop: 10, height: "90%", paddingRight: 10}}>
                        <img
                            src={process.env.PUBLIC_URL + ImagePath.getDesignerUrl() + "vorschau_eigenschaften_format.png"}
                            alt={""}
                            style={{height: tabHeight - 20}}
                        />
                    </div>
                </div>
            }

            {/* Tab: Marketplace */}
            {this.state.openSection === "menu-setting-marketplace" &&
                <div className={"modal-settings-tab"} style={{height: tabHeight}}>

                    <div className="form-group">
                        <div className={"form-row"} style={{marginBottom: 20}}>
                            <img className={"info-icon"}
                                 src={process.env.PUBLIC_URL + ImagePath.getNotificationUrl() + "callsign.svg"}
                                 alt={this.context.translate(translations.notification.information)}/>
                            <div className={"info-text"}>
                                {this.context.translate(translations.text.marketplace.mp_tab_info)}
                            </div>
                        </div>
                    </div>

                    <div className="form-group" style={{width: "100%"}}>
                        <div className={"form-row"} style={{flexGrow: 1}} >
                            {/* Properties */}
                            <div className="form-group" style={{minWidth: "250px", flexGrow: 1}}>
                                <div className={"form-row"} style={{marginBottom: 10}}>
                                    {this.context.translate(translations.text.marketplace.properties)}
                                </div>
                                <CheckBox id={"showName"}
                                          label={this.context.translate(translations.text.marketplace.show_name)}
                                          labelClass={"label label-primary"}
                                          checked={this.state.settings.marketplaceShowName}
                                          readonly={true}
                                          required={false}
                                          style={{width: "100%"}}
                                />
                                <CheckBox id={"showEmail"}
                                          label={this.context.translate(translations.text.marketplace.show_email)}
                                          labelClass={"label label-primary"}
                                          checked={this.state.settings.marketplaceShowEmail}
                                          readonly={true}
                                          required={false}
                                          style={{width: "100%"}}
                                />
                                <CheckBox id={"editingAllowed"}
                                          label={this.context.translate(translations.text.marketplace.editing_allowed)}
                                          checked={this.state.settings.marketplaceIsEditingAllowed}
                                          readonly={true}
                                          required={false}
                                          style={{width: "100%"}}
                                />
                                <CheckBox id={"blackWhite"}
                                          label={this.context.translate(translations.text.worksheet_settings.black_white)}
                                          checked={this.state.settings.blackWhite}
                                          onChange={() => this.onChangeBoolean("blackWhite", this.isMPTabEditingAllowed())}
                                          readonly={!this.isMPTabEditingAllowed()}
                                          required={false}
                                          style={{width: "100%"}}
                                />
                            </div>

                            {/* Other information */}
                            <div className="form-group" style={{minWidth: "300px", flexGrow: 1}}>
                                <div className={"form-row"} style={{marginBottom: 10}}>
                                    {this.context.translate(translations.text.marketplace.target_group)}
                                </div>
                                <CheckBox id={"targetSchool"}
                                          label={this.context.translate(translations.text.marketplace.target_school)}
                                          checked={this.state.settings.marketplaceTargetSchool}
                                          onChange={() => this.onChangeBoolean("marketplaceTargetSchool", this.isMPTabEditingAllowed())}
                                          readonly={!this.isMPTabEditingAllowed()}
                                          required={false}
                                          style={{width: "100%"}}
                                />
                                <CheckBox id={"targetPupils"}
                                          label={this.context.translate(translations.text.marketplace.target_pupils)}
                                          checked={this.state.settings.marketplaceTargetPupils}
                                          onChange={() => this.onChangeBoolean("marketplaceTargetPupils", this.isMPTabEditingAllowed())}
                                          readonly={!this.isMPTabEditingAllowed()}
                                          required={false}
                                          style={{width: "100%"}}
                                />
                                <CheckBox id={"targetTeacher"}
                                          label={this.context.translate(translations.text.marketplace.target_teacher)}
                                          checked={this.state.settings.marketplaceTargetTeacher}
                                          onChange={() => this.onChangeBoolean("marketplaceTargetTeacher", this.isMPTabEditingAllowed())}
                                          readonly={!this.isMPTabEditingAllowed()}
                                          required={false}
                                          style={{width: "100%", paddingRight: 10}}
                                />
                                <CheckBox id={"targetParents"}
                                          label={this.context.translate(translations.text.marketplace.target_parents)}
                                          checked={this.state.settings.marketplaceTargetParents}
                                          onChange={() => this.onChangeBoolean("marketplaceTargetParents", this.isMPTabEditingAllowed())}
                                          readonly={!this.isMPTabEditingAllowed()}
                                          required={false}
                                          style={{width: "100%"}}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className={"form-row"} style={{marginTop: 20, marginBottom: 20}}>
                            <div className={"form-row"}>
                                <TextBox id={"txtTags"}
                                         width={520}
                                         label={this.context.translate(translations.fields.image.tags)}
                                         labelClass={"label-big"}
                                         required={false}
                                         readonly={true}
                                         tags={this.state.settings.tags || []}
                                         mode={{autoComplete: false, tags: true, removeInputField: true}}
                                         value={""}
                                />
                            </div>
                        </div>

                        {this.state.ratings &&
                            <>
                                <div className={"form-row"} style={{marginBottom: 20}}>
                                    {this.context.translate(translations.text_fragment.ratings)}:
                                </div>
                                <div className={"form-row"} style={{marginBottom: 20, alignItems: "flex-start"}}>
                                    <div className={"form-group"} style={{width: 175}}>
                                        <Rating rating={5}
                                                iconSize={20}
                                                legend={this.state.ratings?.filter(x => x.rating === 5).length.toString() || "0"}
                                        />

                                        <Rating rating={2}
                                                iconSize={20}
                                                legend={this.state.ratings?.filter(x => x.rating === 2).length.toString() || "0"}
                                        />
                                    </div>
                                    <div className={"form-group"} style={{width: 175}}>
                                        <Rating rating={4}
                                                iconSize={20}
                                                legend={this.state.ratings?.filter(x => x.rating === 4).length.toString() || "0"}
                                        />
                                        <Rating rating={1}
                                                iconSize={20}
                                                legend={this.state.ratings?.filter(x => x.rating === 1).length.toString() || "0"}
                                        />
                                    </div>
                                    <div className={"form-group"} style={{width: 175}}>
                                        <Rating rating={3}
                                                iconSize={20}
                                                legend={this.state.ratings?.filter(x => x.rating === 3).length.toString() || "0"}
                                        />
                                    </div>
                                </div>

                                <div className={"form-row"} style={{marginTop: 10, alignItems: "flex-start"}}>
                                    <div className={"form-group"} style={{width: "100%"}}>
                                        <RatingList ratings={this.state.ratings || []}/>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="form-group">
                            <div className={"form-row"} style={{width: "300px"}}>
                                <CheckBox id={"advertisement"}
                                          label={this.context.translate(translations.text.marketplace.advertisement)}
                                          checked={this.state.settings.marketplaceAdvertisement}
                                          readonly={true}
                                          required={false}
                                          style={{width: 500}}
                                />
                            </div>
                        </div>

                        <div className={"form-row marketplace-settings-list"} style={{marginTop: 20}}>
                            <List items={logs}
                                  definitions={this.columnDefinition}
                                  objectType={AdminObjectType.worksheet_log}
                                  selectedItems={[]}
                                  paginationOptions={{showPagination: false}}
                                  sortOptions={{
                                      allowSort: true,
                                      sortField: "createdOn",
                                      sortDescending: true
                                  }}
                                  allowMultiSelect={false}
                                  history={this.props.history}
                                  location={this.props.location}
                                  match={this.props.match}
                            />
                        </div>
                    </div>
                </div>
            }
        </Modal>
    }
}
